import { useInfiniteQuery } from '@tanstack/react-query';

import { PostDto } from 'api/posts/dto/post.dto';
import PostsMethods from 'api/posts/PostsMethods';

const take = 6;

export const useFetchInfiniteTrendingPosts = () => {
  return useInfiniteQuery<PostDto[], Error>({
    queryKey: [`infinite-trending-posts`],
    queryFn: ({ pageParam }) => {
      const page = typeof pageParam === 'number' ? pageParam : 1;
      return PostsMethods.getTrendingPost(page, take);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage || lastPage.length < take) return undefined;
      return allPages.length + 1;
    },
    initialPageParam: 1,
  });
};
