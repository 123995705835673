import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import LightBox from 'components/LightBox';
import ConfirmModal from 'components/modals/ConfirmModal';
import Button from 'components/UI/Button';
import Category from 'components/UI/Category';
import UserInfo from 'components/UserInfo';
import { PostDto } from 'api/posts/dto/post.dto';
import { useMutationRemovePost } from 'hooks/mutations/useMutationRemovePost';
import { useMutationSkipPost } from 'hooks/mutations/useMutationSkipPost';
import useResize from 'hooks/useResize';
import CommentIcon from 'assets/icons/CommentIcon';
import LikeIcon from 'assets/icons/LikeIcon';
import ViewIcon from 'assets/icons/ViewIcon';
import WarningIcon from 'assets/icons/WarningIcon';

import s from './PostCard.module.scss';

interface PostCardProps {
  post: PostDto;
  isSkip?: boolean;
  isOne?: boolean;
}

export default function PostCard({ post, isSkip, isOne }: PostCardProps) {
  const asset = post.assets[0];
  const user = post.user;
  const [verdict, setVerdict] = useState({
    isSkip: false,
    isRemove: false,
  });
  const [isModal, setIsModal] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const width = useResize();

  const navigate = useNavigate();

  const { skipPost } = useMutationSkipPost();
  const { deletePost } = useMutationRemovePost();

  const closeModal = useCallback(() => {
    setIsModal(false);
    setVerdict({
      isRemove: false,
      isSkip: false,
    });
  }, []);

  const handleConfirm = useCallback(() => {
    if (verdict.isRemove) {
      deletePost(post.id);
    }
    if (verdict.isSkip) {
      skipPost(post.id);
    }
    closeModal();
  }, [closeModal, deletePost, skipPost, post.id, verdict]);

  return (
    <>
      {isFullScreen && (
        <LightBox
          imageUrl={asset.thumbnail_url}
          onCloseRequest={() => setIsFullScreen(false)}
        />
      )}
      <ConfirmModal
        isOpen={isModal}
        closeModal={closeModal}
        handleConfirm={handleConfirm}
      />
      <div
        className={classNames({
          [s.container]: true,
          [s.one]: width >= 767 || width >= 1240 ? isOne : false,
        })}
        style={{
          cursor: isOne ? 'default' : 'pointer',
        }}
        onClick={isOne ? () => {} : () => navigate(`/post/${post.id}`)}
      >
        {asset.source_type === 'video' ? (
          <div className={s.imageWrapper}>
            <video
              src={asset.url}
              className={s.image}
              onClick={(event) => event.stopPropagation()}
              controls
              autoPlay
              muted
              loop
            />
          </div>
        ) : (
          <div
            className={s.imageWrapper}
            onClick={(event) => {
              event.stopPropagation();
              setIsFullScreen(true);
            }}
          >
            <img src={asset.thumbnail_url} alt="report" className={s.image} />
          </div>
        )}
        <div className={s.content}>
          <UserInfo
            user={{
              avatar: user.avatar,
              nickname: user.nickname,
              created_at: user.created_at,
              isAdaptyActive: user.adapty.is_active,
            }}
            isPost
          />
          <div className={s.post}>
            <p className={s.postPrompt}>{asset.source}</p>
            <div className={s.postTeg}>
              {asset.style && <Category>{asset.style}</Category>}
            </div>
          </div>
          <div className={s.cardFooter}>
            <div
              className={classNames({
                [s.buttons]: true,
                [s.skip]: !isSkip,
              })}
            >
              {isSkip && (
                <Button
                  variant="green"
                  className={s.button}
                  onClick={(event) => {
                    event.stopPropagation();
                    setVerdict({
                      ...verdict,
                      isSkip: true,
                    });
                    setIsModal(true);
                  }}
                >
                  Skip
                </Button>
              )}
              <Button
                variant="red"
                className={s.button}
                onClick={(event) => {
                  event.stopPropagation();
                  setIsModal(true);
                  setVerdict({
                    ...verdict,
                    isRemove: true,
                  });
                }}
              >
                Remove
              </Button>
            </div>
            <div className={s.postStatistic}>
              <div className={s.statInfo}>
                <WarningIcon />
                <p>{post.strikes_count}</p>
              </div>
              <div className={s.statInfo}>
                <LikeIcon />
                <p>{post.likes_count}</p>
              </div>
              <div className={s.statInfo}>
                <CommentIcon />
                <p>{post.comments_count}</p>
              </div>
              <div className={s.statInfo}>
                <ViewIcon />
                <p>{post.views_count}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
