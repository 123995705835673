export const STRIKENAVIGATION = [
  {
    id: 1,
    title: 'Posts',
    link: '/strike/posts',
  },
  {
    id: 2,
    title: 'Users',
    link: '/strike/users',
  },
];
