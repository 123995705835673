import { SVGProps } from 'react';

export default function UsersGroupIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75C10.2051 2.75 8.75 4.20507 8.75 6C8.75 7.79493 10.2051 9.25 12 9.25C13.7949 9.25 15.25 7.79493 15.25 6C15.25 4.20507 13.7949 2.75 12 2.75ZM7.25 6C7.25 3.37665 9.37665 1.25 12 1.25C14.6234 1.25 16.75 3.37665 16.75 6C16.75 8.62335 14.6234 10.75 12 10.75C9.37665 10.75 7.25 8.62335 7.25 6Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 4C17.25 3.58579 17.5858 3.25 18 3.25C19.9373 3.25 21.75 4.58285 21.75 6.5C21.75 8.41715 19.9373 9.75 18 9.75C17.5858 9.75 17.25 9.41421 17.25 9C17.25 8.58579 17.5858 8.25 18 8.25C19.3765 8.25 20.25 7.34427 20.25 6.5C20.25 5.65573 19.3765 4.75 18 4.75C17.5858 4.75 17.25 4.41421 17.25 4Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4.75C4.62352 4.75 3.75 5.65573 3.75 6.5C3.75 7.34427 4.62352 8.25 6 8.25C6.41421 8.25 6.75 8.58579 6.75 9C6.75 9.41421 6.41421 9.75 6 9.75C4.06278 9.75 2.25 8.41715 2.25 6.5C2.25 4.58285 4.06278 3.25 6 3.25C6.41421 3.25 6.75 3.58579 6.75 4C6.75 4.41421 6.41421 4.75 6 4.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17338 14.7956C7.22169 15.4301 6.75 16.2242 6.75 17C6.75 17.7758 7.22169 18.5699 8.17338 19.2044C9.11984 19.8354 10.4706 20.25 12 20.25C13.5294 20.25 14.8802 19.8354 15.8266 19.2044C16.7783 18.5699 17.25 17.7758 17.25 17C17.25 16.2242 16.7783 15.4301 15.8266 14.7956C14.8802 14.1646 13.5294 13.75 12 13.75C10.4706 13.75 9.11984 14.1646 8.17338 14.7956ZM7.34133 13.5475C8.56645 12.7308 10.2157 12.25 12 12.25C13.7843 12.25 15.4335 12.7308 16.6587 13.5475C17.8785 14.3608 18.75 15.5666 18.75 17C18.75 18.4334 17.8785 19.6392 16.6587 20.4525C15.4335 21.2692 13.7843 21.75 12 21.75C10.2157 21.75 8.56645 21.2692 7.34133 20.4525C6.12146 19.6392 5.25 18.4334 5.25 17C5.25 15.5666 6.12146 14.3608 7.34133 13.5475Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2676 13.8395C19.3563 13.4349 19.7562 13.1788 20.1608 13.2676C21.1227 13.4785 21.9894 13.8595 22.6329 14.3861C23.2759 14.9122 23.7502 15.6354 23.7502 16.5002C23.7502 17.3649 23.2759 18.0881 22.6329 18.6143C21.9894 19.1408 21.1227 19.5218 20.1608 19.7327C19.7562 19.8215 19.3563 19.5654 19.2676 19.1608C19.1788 18.7562 19.4349 18.3563 19.8395 18.2676C20.6318 18.0938 21.2651 17.7953 21.683 17.4534C22.1015 17.1109 22.2502 16.7765 22.2502 16.5002C22.2502 16.2238 22.1015 15.8894 21.683 15.547C21.2651 15.205 20.6318 14.9065 19.8395 14.7327C19.4349 14.644 19.1788 14.2441 19.2676 13.8395Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.73259 13.8395C4.82132 14.2441 4.56525 14.644 4.16065 14.7328C3.36828 14.9065 2.73504 15.205 2.31712 15.5469C1.89863 15.8894 1.75 16.2238 1.75 16.5002C1.75 16.7765 1.89863 17.1109 2.31712 17.4534C2.73504 17.7953 3.36828 18.0938 4.16065 18.2676C4.56525 18.3563 4.82132 18.7562 4.73259 19.1608C4.64386 19.5654 4.24394 19.8215 3.83935 19.7328C2.87747 19.5218 2.01071 19.1408 1.36719 18.6142C0.724244 18.0881 0.25 17.3649 0.25 16.5002C0.25 15.6354 0.724244 14.9122 1.36719 14.3861C2.01071 13.8595 2.87747 13.4785 3.83935 13.2676C4.24394 13.1788 4.64386 13.4349 4.73259 13.8395Z"
        fill="white"
      />
    </svg>
  );
}
