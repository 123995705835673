import { Link, NavLink } from 'react-router-dom';

import Loader from 'components/UI/Loader';
import { useFetchMyProfile } from 'hooks/query/useFetchMyProfile';
import logo from 'assets/images/logo.png';

import s from './Header.module.scss';

export default function Header() {
  const { myProfile, isLoadingMyProfile } = useFetchMyProfile();

  return (
    <header className={s.header}>
      {isLoadingMyProfile ? (
        <Loader />
      ) : (
        <Link to={`/profile/${myProfile?.nickname}`} className={s.user}>
          <img className={s.user__image} src={myProfile?.avatar} alt="avatar" />
          <p>{myProfile?.nickname}</p>
        </Link>
      )}
    </header>
  );
}
