import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import getDeviceId from 'utils/getDeviceId';

interface SettingProps {
  deviceId: string;
}

const initialState: SettingProps = {
  deviceId: getDeviceId(),
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setDeviceId: (state, action: PayloadAction<string>) => {
      state.deviceId = action.payload;
    },
  },
});

export const {} = settingsSlice.selectors;
export default settingsSlice.reducer;
