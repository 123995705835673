import { useParams } from 'react-router-dom';

import Loader from 'components/UI/Loader';
import PostCard from 'pages/StrikePostsPage/components/PostCard';
import { useFetchPostById } from 'hooks/query/useFetchPostById';
import PostComments from './components/PostComments';
import PostDetail from './components/PostDetail';

import s from './PostPage.module.scss';

export default function PostPage() {
  const { id } = useParams();

  const { post, isPostLoading } = useFetchPostById(+id!);

  if (isPostLoading) {
    return (
      <div className="loaderWrapper">
        <Loader />
      </div>
    );
  }

  return post ? (
    <>
      <section className={s.postDetail}>
        <PostDetail post={post} />
        <PostCard post={post} isOne />
      </section>
      <PostComments post={post} />
    </>
  ) : null;
}
