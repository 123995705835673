import { Link } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

import { createdUserDate } from 'utils/formatData';
import SubscribeIcon from 'assets/icons/SubscribeIcon';

import s from './UserInfo.module.scss';

type IUser = {
  avatar: string;
  created_at?: string;
  isAdaptyActive?: boolean;
  nickname: string;
};

interface UserInfoProps {
  user: IUser;
  isPost?: boolean;
}

export default function UserInfo({ user, isPost }: UserInfoProps) {
  return (
    <Link
      to={`/profile/${user.nickname}`}
      className={s.userInfo}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={s.userAvatar}>
        <img src={user.avatar} alt="avatar" className={s.image} />
      </div>
      <div className={s.textInfo}>
        <p className={s.userNick}>
          {user.nickname}{' '}
          {user.isAdaptyActive && <SubscribeIcon width={18} height={18} />}
        </p>
        <p className={s.time}>
          {user.created_at &&
            (isPost ? (
              <ReactTimeAgo date={new Date(user.created_at)} locale="en" />
            ) : (
              `Created at: ${createdUserDate(user.created_at)}`
            ))}
        </p>
      </div>
    </Link>
  );
}
