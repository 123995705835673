import { useQuery } from '@tanstack/react-query';

import { UserDto } from 'api/user/dto/user.dto';
import UserMethods from 'api/user/UserMethods';
import { useAppSelector } from 'hooks/store';

export function useFetchMyProfile() {
  const { token } = useAppSelector((state) => state.auth);

  const { data: myProfile, isLoading: isLoadingMyProfile } = useQuery<UserDto>({
    queryKey: ['my-profile'],
    queryFn: () => UserMethods.getProfile(),
    enabled: !!token,
  });

  return { myProfile, isLoadingMyProfile };
}
