import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import { useLogout } from 'hooks/auth/useLogout';
import { useFetchMyProfile } from 'hooks/query/useFetchMyProfile';
import { useAppSelector } from 'hooks/store';
import useResize from 'hooks/useResize';
import Aside from './Aside';
import FooterTabs from './FooterTabs';
import Header from './Header';

import s from './Layout.module.scss';

export default function Layout() {
  const { myProfile } = useFetchMyProfile();
  const { mutate: logout } = useLogout();
  const width = useResize();

  const { deviceId } = useAppSelector((state) => state.settings);

  TimeAgo.addLocale(en);

  useEffect(() => {
    if (myProfile && myProfile.role !== 'admin') {
      logout();
      window.location.href = 'https://magicly.ai/';
    }
  }, [myProfile, logout]);

  useEffect(() => {
    window.localStorage.setItem('device-id', deviceId);
  }, []);

  return (
    <div className={s.layout}>
      {width > 1240 && <Header />}
      {width > 1240 && <Aside />}
      {width < 1240 && <FooterTabs />}
      <main className={s.main}>
        <Outlet />
      </main>
    </div>
  );
}
