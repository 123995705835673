import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { FOOTERTABS } from 'constants/navigations.constants';

import s from './FooterTabs.module.scss';

export default function FooterTabs() {
  const location = useLocation();

  const isActive = useCallback(
    (path: string) => location.pathname === path,
    [location.pathname],
  );

  return (
    <div className={s.footer}>
      {FOOTERTABS.map((elem) => (
        <Link
          key={elem.id}
          to={elem.link}
          className={classNames({
            [s.tab]: true,
            [s.active]: isActive,
          })}
        >
          {isActive(elem.link) ? elem.iconActive : elem.icon}
          <p className={s.tab__text}>{elem.title}</p>
        </Link>
      ))}
    </div>
  );
}
