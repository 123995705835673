import docs from 'assets/images/API.png';
import fire from 'assets/images/firebase.png';
import minio from 'assets/images/minio.png';
import pg from 'assets/images/pg.png';
import rabbit from 'assets/images/rabbit.png';
import stats from 'assets/images/stats.png';

export const DASHBOARDLIST = [
  {
    id: 1,
    img: docs,
    title: 'Docs API',
    link: 'https://core.retomagic.com/docs',
  },
  {
    id: 2,
    img: stats,
    title: 'Core Stats',
    link: 'https://core.retomagic.com/stats',
  },
  {
    id: 3,
    img: fire,
    title: 'Firebase',
    link: 'https://console.firebase.google.com/u/0/project/retomagic-rt228/overview',
  },
  {
    id: 4,
    img: rabbit,
    title: 'RabbitMQ',
    link: 'http://195.211.239.206:8095',
  },
  {
    id: 5,
    img: pg,
    title: 'PgAdmin',
    link: 'http://195.211.239.206:8092',
  },
  {
    id: 6,
    img: minio,
    title: 'Minio',
    link: 'http://195.211.239.206:8094',
  },
];
