import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import MobileHeader from 'components/MobileHeader';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';
import { setAuthInitialization } from 'store/slices/authSlice';
import { useLogout } from 'hooks/auth/useLogout';
import { useFetchMyProfile } from 'hooks/query/useFetchMyProfile';
import { useFetchUserByNickname } from 'hooks/query/useFetchUserByNickname';
import { useAppDispatch } from 'hooks/store';
import ProfileActions from './components/ProfileActions';
import ProfileDetail from './components/ProfileDetail';
import ProfileInfo from './components/ProfileInfo';
import ProfilePosts from './components/ProfilePosts';

import s from './ProfilePage.module.scss';

export default function ProfilePage() {
  const { nickname } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { myProfile } = useFetchMyProfile();
  const { user, isLoadingUser } = useFetchUserByNickname(nickname);
  const { mutate: logout } = useLogout();

  const isMe = useMemo(() => {
    return user?.nickname === myProfile?.nickname;
  }, [user, myProfile]);

  const handleLogout = useCallback(() => {
    logout();
    dispatch(setAuthInitialization(false));
    navigate('/login');
  }, [logout]);

  if (isLoadingUser) {
    return (
      <div className="loaderWrapper">
        <Loader />
      </div>
    );
  }

  return user ? (
    <>
      <MobileHeader
        title={user?.nickname}
        isArrow
        rightButton={
          isMe && (
            <Button className={s.button} onClick={handleLogout}>
              Logout
            </Button>
          )
        }
      />
      <ProfileDetail user={user} />
      <ProfileInfo user={user} />
      {!isMe && <ProfileActions user={user} />}
      <ProfilePosts id={user.id} />
    </>
  ) : null;
}
