import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AdaptyData } from 'components/modals/AdaptyModal/AdaptyModal';
import UserMethods from 'api/user/UserMethods';

interface Props {
  userId: number;
  data: AdaptyData;
}

export function useMutationGrantAdapty(nickname: string) {
  const queryClient = useQueryClient();

  const { mutateAsync: adaptyGrant } = useMutation({
    mutationKey: ['grant adapty'],
    mutationFn: ({ userId, data }: Props) =>
      UserMethods.postIssueAdapty(userId, data),
    onSuccess(data) {
      if (data) {
        queryClient.invalidateQueries({
          queryKey: [nickname],
        });
        toast.success('Premium issued successfully!');
      } else {
        toast.error(
          'The user was not authorized through the mobile application!',
        );
      }
    },
    onError(error) {
      console.log(error);
      toast.error('Something went wrong! Try again...');
    },
  });
  return { adaptyGrant };
}
