import { Route, Routes } from 'react-router-dom';

import Layout from 'components/Layout';
import AuthPage from 'pages/AuthPage';
import DashboardPage from 'pages/DashboardPage';
import PostPage from 'pages/PostPage';
import ProfilePage from 'pages/ProfilePage';
import SettingsPage from 'pages/SettingsPage';
import StrikePage from 'pages/StrikePage';
import StrikePostsPage from 'pages/StrikePostsPage';
import StrikeUsersPage from 'pages/StrikeUsersPage';
import TrendingPage from 'pages/TrendingPage';
import { useAppSelector } from 'hooks/store';

export default function Router() {
  const { isAuthInitialized } = useAppSelector((state) => state.auth);

  return (
    <Routes>
      {isAuthInitialized ? (
        <Route path="/*" element={<Layout />}>
          <Route index element={<DashboardPage />} />
          <Route path="trending" element={<TrendingPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="strike" element={<StrikePage />} />
          <Route path="strike/posts" element={<StrikePostsPage />} />
          <Route path="strike/users" element={<StrikeUsersPage />} />
          <Route path="profile/:nickname" element={<ProfilePage />} />
          <Route path="post/:id" element={<PostPage />} />
        </Route>
      ) : (
        <Route path="login" element={<AuthPage />} />
      )}
    </Routes>
  );
}
