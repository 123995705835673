interface Props {
  isActive?: boolean;
}

export default function DashboardIcon({ isActive }: Props) {
  return isActive ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54734 5.31244C4.98053 5.41655 4.65246 5.58373 4.40901 5.82718C4.13225 6.10394 3.9518 6.49252 3.85315 7.22629C3.75159 7.98164 3.75 8.98275 3.75 10.4182V16.4182C3.75 17.8536 3.75159 18.8547 3.85315 19.6101C3.9518 20.3438 4.13225 20.7324 4.40899 21.0091L4.40902 21.0091C4.68578 21.2859 5.07435 21.4664 5.80812 21.565C6.56347 21.6666 7.56458 21.6682 9 21.6682H15C16.4354 21.6682 17.4365 21.6666 18.1918 21.565C18.9256 21.4664 19.3142 21.2859 19.591 21.0091L19.591 21.0091C19.8677 20.7324 20.0482 20.3438 20.1469 19.61C20.2484 18.8547 20.25 17.8536 20.25 16.4182V10.4182C20.25 8.98275 20.2484 7.98164 20.1469 7.22629C20.0482 6.49252 19.8677 6.10395 19.591 5.82719C19.3475 5.58373 19.0194 5.41655 18.4526 5.31244C17.8644 5.20439 17.0893 5.17599 15.9958 5.1699L16.0042 3.66992C17.0857 3.67594 17.9871 3.70183 18.7236 3.83712C19.4816 3.97635 20.1267 4.24159 20.6516 4.76651C21.2536 5.36843 21.5125 6.12631 21.6335 7.02642C21.75 7.89339 21.75 8.9957 21.75 10.3633V16.473C21.75 17.8406 21.75 18.9429 21.6335 19.8099C21.5125 20.71 21.2536 21.4679 20.6516 22.0698M20.6516 22.0698C20.0497 22.6717 19.2918 22.9306 18.3917 23.0516C17.5248 23.1682 16.4225 23.1682 15.0549 23.1682H8.94512C7.57753 23.1682 6.47521 23.1682 5.60825 23.0516C4.70814 22.9306 3.95026 22.6717 3.34834 22.0698C2.74642 21.4679 2.48754 20.71 2.36652 19.8099C2.24996 18.9429 2.24998 17.8406 2.25 16.473V10.3633C2.24998 8.99571 2.24996 7.89339 2.36652 7.02642C2.48754 6.12631 2.74643 5.36844 3.34835 4.76652C3.87328 4.24159 4.51835 3.97635 5.27635 3.83712C6.01291 3.70183 6.91429 3.67594 7.99582 3.66992L8.00418 5.1699C6.91068 5.17599 6.13559 5.20439 5.54734 5.31244"
        fill="url(#paint0_linear_8644_41159)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 14.418C9.75 14.0038 10.0858 13.668 10.5 13.668H17C17.4142 13.668 17.75 14.0038 17.75 14.418C17.75 14.8322 17.4142 15.168 17 15.168H10.5C10.0858 15.168 9.75 14.8322 9.75 14.418Z"
        fill="url(#paint1_linear_8644_41159)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 14.418C6.25 14.0038 6.58579 13.668 7 13.668H7.5C7.91421 13.668 8.25 14.0038 8.25 14.418C8.25 14.8322 7.91421 15.168 7.5 15.168H7C6.58579 15.168 6.25 14.8322 6.25 14.418Z"
        fill="url(#paint2_linear_8644_41159)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 10.918C6.25 10.5038 6.58579 10.168 7 10.168H7.5C7.91421 10.168 8.25 10.5038 8.25 10.918C8.25 11.3322 7.91421 11.668 7.5 11.668H7C6.58579 11.668 6.25 11.3322 6.25 10.918Z"
        fill="url(#paint3_linear_8644_41159)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 17.918C6.25 17.5038 6.58579 17.168 7 17.168H7.5C7.91421 17.168 8.25 17.5038 8.25 17.918C8.25 18.3322 7.91421 18.668 7.5 18.668H7C6.58579 18.668 6.25 18.3322 6.25 17.918Z"
        fill="url(#paint4_linear_8644_41159)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 10.918C9.75 10.5038 10.0858 10.168 10.5 10.168H17C17.4142 10.168 17.75 10.5038 17.75 10.918C17.75 11.3322 17.4142 11.668 17 11.668H10.5C10.0858 11.668 9.75 11.3322 9.75 10.918Z"
        fill="url(#paint5_linear_8644_41159)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 17.918C9.75 17.5038 10.0858 17.168 10.5 17.168H17C17.4142 17.168 17.75 17.5038 17.75 17.918C17.75 18.3322 17.4142 18.668 17 18.668H10.5C10.0858 18.668 9.75 18.3322 9.75 17.918Z"
        fill="url(#paint6_linear_8644_41159)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 3.16797C9.08578 3.16797 8.75 3.50375 8.75 3.91797V4.91797C8.75 5.33219 9.08578 5.66797 9.5 5.66797H14.5C14.9142 5.66797 15.25 5.33218 15.25 4.91797V3.91797C15.25 3.50376 14.9142 3.16797 14.5 3.16797H9.5ZM7.25 3.91797C7.25 2.67533 8.25736 1.66797 9.5 1.66797H14.5C15.7426 1.66797 16.75 2.67532 16.75 3.91797V4.91797C16.75 6.16062 15.7426 7.16797 14.5 7.16797H9.5C8.25736 7.16797 7.25 6.16061 7.25 4.91797V3.91797Z"
        fill="url(#paint7_linear_8644_41159)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8644_41159"
          x1="-1.33661"
          y1="13.419"
          x2="24.0085"
          y2="25.7448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8644_41159"
          x1="8.27857"
          y1="14.418"
          x2="9.9427"
          y2="18.7338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8644_41159"
          x1="5.88214"
          y1="14.418"
          x2="8.14512"
          y2="15.8852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8644_41159"
          x1="5.88214"
          y1="10.918"
          x2="8.14512"
          y2="12.3852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8644_41159"
          x1="5.88214"
          y1="17.918"
          x2="8.14512"
          y2="19.3852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8644_41159"
          x1="8.27857"
          y1="10.918"
          x2="9.9427"
          y2="15.2338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8644_41159"
          x1="8.27857"
          y1="17.918"
          x2="9.9427"
          y2="22.2338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_8644_41159"
          x1="5.50268"
          y1="4.41797"
          x2="14.4549"
          y2="11.9372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54734 4.89447C4.98053 4.99858 4.65246 5.16576 4.40901 5.40921C4.13225 5.68597 3.9518 6.07455 3.85315 6.80832C3.75159 7.56367 3.75 8.56478 3.75 10.0002V16.0002C3.75 17.4356 3.75159 18.4367 3.85315 19.1921C3.9518 19.9259 4.13225 20.3144 4.40899 20.5911L4.40902 20.5912C4.68578 20.8679 5.07435 21.0484 5.80812 21.147C6.56347 21.2486 7.56458 21.2502 9 21.2502H15C16.4354 21.2502 17.4365 21.2486 18.1918 21.147C18.9256 21.0484 19.3142 20.8679 19.591 20.5912L19.591 20.5911C19.8677 20.3144 20.0482 19.9259 20.1469 19.1921C20.2484 18.4367 20.25 17.4356 20.25 16.0002V10.0002C20.25 8.56478 20.2484 7.56367 20.1469 6.80833C20.0482 6.07455 19.8677 5.68598 19.591 5.40922C19.3475 5.16576 19.0194 4.99858 18.4526 4.89447C17.8644 4.78642 17.0893 4.75802 15.9958 4.75193L16.0042 3.25195C17.0857 3.25797 17.9871 3.28386 18.7236 3.41915C19.4816 3.55838 20.1267 3.82362 20.6516 4.34854C21.2536 4.95046 21.5125 5.70834 21.6335 6.60845C21.75 7.47542 21.75 8.57773 21.75 9.94531V16.055C21.75 17.4226 21.75 18.525 21.6335 19.392C21.5125 20.292 21.2536 21.0499 20.6516 21.6518M20.6516 21.6519C20.0497 22.2538 19.2918 22.5126 18.3917 22.6337C17.5248 22.7502 16.4225 22.7502 15.0549 22.7502H8.94512C7.57753 22.7502 6.47521 22.7502 5.60825 22.6337C4.70814 22.5126 3.95026 22.2537 3.34834 21.6518C2.74642 21.0499 2.48754 20.292 2.36652 19.392C2.24996 18.525 2.24998 17.4226 2.25 16.0551V9.94532C2.24998 8.57774 2.24996 7.47542 2.36652 6.60845C2.48754 5.70834 2.74643 4.95047 3.34835 4.34855C3.87328 3.82362 4.51835 3.55838 5.27635 3.41915C6.01291 3.28386 6.91429 3.25797 7.99582 3.25195L8.00418 4.75193C6.91068 4.75802 6.13559 4.78642 5.54734 4.89447"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 14C9.75 13.5858 10.0858 13.25 10.5 13.25H17C17.4142 13.25 17.75 13.5858 17.75 14C17.75 14.4142 17.4142 14.75 17 14.75H10.5C10.0858 14.75 9.75 14.4142 9.75 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 14C6.25 13.5858 6.58579 13.25 7 13.25H7.5C7.91421 13.25 8.25 13.5858 8.25 14C8.25 14.4142 7.91421 14.75 7.5 14.75H7C6.58579 14.75 6.25 14.4142 6.25 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 10.5C6.25 10.0858 6.58579 9.75 7 9.75H7.5C7.91421 9.75 8.25 10.0858 8.25 10.5C8.25 10.9142 7.91421 11.25 7.5 11.25H7C6.58579 11.25 6.25 10.9142 6.25 10.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 17.5C6.25 17.0858 6.58579 16.75 7 16.75H7.5C7.91421 16.75 8.25 17.0858 8.25 17.5C8.25 17.9142 7.91421 18.25 7.5 18.25H7C6.58579 18.25 6.25 17.9142 6.25 17.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 10.5C9.75 10.0858 10.0858 9.75 10.5 9.75H17C17.4142 9.75 17.75 10.0858 17.75 10.5C17.75 10.9142 17.4142 11.25 17 11.25H10.5C10.0858 11.25 9.75 10.9142 9.75 10.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 17.5C9.75 17.0858 10.0858 16.75 10.5 16.75H17C17.4142 16.75 17.75 17.0858 17.75 17.5C17.75 17.9142 17.4142 18.25 17 18.25H10.5C10.0858 18.25 9.75 17.9142 9.75 17.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2.75C9.08578 2.75 8.75 3.08578 8.75 3.5V4.5C8.75 4.91422 9.08578 5.25 9.5 5.25H14.5C14.9142 5.25 15.25 4.91421 15.25 4.5V3.5C15.25 3.08579 14.9142 2.75 14.5 2.75H9.5ZM7.25 3.5C7.25 2.25736 8.25736 1.25 9.5 1.25H14.5C15.7426 1.25 16.75 2.25735 16.75 3.5V4.5C16.75 5.74265 15.7426 6.75 14.5 6.75H9.5C8.25736 6.75 7.25 5.74264 7.25 4.5V3.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
