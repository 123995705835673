import { SVGProps } from 'react';

export default function GoBackIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99895 18.7495C9.80695 18.7495 9.61495 18.6775 9.46795 18.5305L3.46795 12.5305C3.39295 12.4555 3.33895 12.3715 3.30295 12.2785C3.30295 12.2785 3.30295 12.2785 3.30295 12.2755C3.27295 12.1975 3.25495 12.1135 3.25195 12.0265V12.0235C3.25195 12.0145 3.25195 12.0085 3.25195 11.9995C3.25195 11.9905 3.25195 11.9845 3.25195 11.9755V11.9725C3.25495 11.8855 3.27295 11.8015 3.30595 11.7235C3.30595 11.7235 3.30595 11.7235 3.30595 11.7205C3.34195 11.6305 3.39895 11.5435 3.47095 11.4685L9.47095 5.46855C9.76495 5.17455 10.239 5.17455 10.533 5.46855C10.827 5.76255 10.827 6.23655 10.533 6.53055L5.81095 11.2495H20.001C20.415 11.2495 20.751 11.5855 20.751 11.9995C20.751 12.4135 20.415 12.7495 20.001 12.7495H5.81095L10.53 17.4685C10.824 17.7625 10.824 18.2365 10.53 18.5305C10.383 18.6775 10.191 18.7495 9.99895 18.7495Z"
        fill="currentColor"
      />
    </svg>
  );
}
