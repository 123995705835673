import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import PostsMethods from 'api/posts/PostsMethods';

export function useMutationSkipPost() {
  const queryClient = useQueryClient();

  const { mutate: skipPost } = useMutation({
    mutationKey: ['skip post'],
    mutationFn: (id: number) => PostsMethods.deleteStrikePost(id),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['infinite-strike-posts'],
      });
      queryClient.invalidateQueries({
        queryKey: ['infinite-trending-posts'],
      });
      queryClient.invalidateQueries({
        queryKey: ['infinite-profile-posts'],
      });
      toast.success('Removing a strike from a post');
    },
    onError() {
      toast.error('Something went wrong! Try again...');
    },
  });

  return { skipPost };
}
