import { Link } from 'react-router-dom';

import RightArrowIcon from 'assets/icons/RightArrowIcon';

import s from './DashboardCard.module.scss';

interface DashboardCardProps {
  img: string;
  title: string;
  link: string;
}

export default function DashboardCard({
  img,
  title,
  link,
}: DashboardCardProps) {
  return (
    <Link to={link} target="_blank" className={s.dashboardCard}>
      <div className={s.info}>
        <div className={s.imageWrapper}>
          <img src={img} alt={title} className={s.image} />
        </div>
        <p>{title}</p>
      </div>
      <RightArrowIcon />
    </Link>
  );
}
