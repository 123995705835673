import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface IModal {
  isOpen: boolean;
  children: React.ReactNode;
}

export default function Modal({ children, isOpen }: IModal) {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const originalOverflowX = window.getComputedStyle(document.body).overflowX;

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.overflowX = 'hidden';
    }

    return () => {
      document.body.style.overflow = originalStyle;
      document.body.style.overflowX = originalOverflowX;
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal"
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
