import { useInfiniteQuery } from '@tanstack/react-query';

import PostsMethods from 'api/posts/PostsMethods';

const take = 3;

interface Props {
  childrenId: number;
  parentId: number;
  isChildren: number;
}

export function useFetchInfiniteChildrenComments({
  childrenId,
  parentId,
  isChildren,
}: Props) {
  return useInfiniteQuery({
    queryKey: [`infinite-childrenComments-${childrenId}`],
    queryFn: ({ pageParam }) => {
      const page = typeof pageParam === 'number' ? pageParam : 1;
      return PostsMethods.getCommentsFromParentComment({
        childrenId,
        parentId,
        page,
        take,
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage || lastPage.length < take) return undefined;
      return allPages.length + 1;
    },
    enabled: isChildren !== 0,
    retry: false,
  });
}
