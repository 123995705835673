import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import UserMethods from 'api/user/UserMethods';

export function useMutationShowTrendsUser(nickname: string) {
  const queryClient = useQueryClient();

  const { mutate: showTrendsUser } = useMutation({
    mutationKey: ['show trends user'],
    mutationFn: (id: number) => UserMethods.postShowTrendsUser(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [nickname],
      });
      toast.success('Unblocking a user in a feed!');
    },
    onError: () => {
      toast.error('Something went wrong! Try again...');
    },
  });

  return { showTrendsUser };
}
