import { useInfiniteQuery } from '@tanstack/react-query';

import { PostDto } from 'api/posts/dto/post.dto';
import UserMethods from 'api/user/UserMethods';

const take = 6;

export function useFetchInfiniteProfilePosts(id: number) {
  return useInfiniteQuery<PostDto[]>({
    queryKey: [`infinite-profile-posts-${id}`],
    queryFn: ({ pageParam }) => {
      const page = typeof pageParam === 'number' ? pageParam : 1;
      return UserMethods.getPostsByUserId(id, page, take);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage || lastPage.length < take) return undefined;
      return allPages.length + 1;
    },
    initialPageParam: 1,
    enabled: !!id,
  });
}
