import { User, UserInfo } from 'firebase/auth';

const convertUserToUserInfo = (user: User | null): UserInfo | null => {
  if (user) {
    const { displayName, email, phoneNumber, photoURL, providerId, uid } = user;
    return {
      displayName,
      email,
      phoneNumber,
      photoURL,
      providerId,
      uid,
    };
  }
  return null;
};

export default convertUserToUserInfo;
