import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import PostsMethods from 'api/posts/PostsMethods';

export function useMutationRemovePost() {
  const queryClient = useQueryClient();

  const { mutate: deletePost } = useMutation({
    mutationKey: ['remove post'],
    mutationFn: (id: number) => PostsMethods.deletePost(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['posts'],
      });
      toast.success('Deleting a post');
    },
    onError: () => {
      toast.error('Something went wrong! Try again...');
    },
  });

  return { deletePost };
}
