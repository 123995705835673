import { SVGProps } from 'react';

export default function PostsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90539 8.31654C6.44393 8.37858 6.24644 8.4858 6.11613 8.61612C5.98581 8.74643 5.87858 8.94393 5.81654 9.40539C5.7516 9.88843 5.75 10.536 5.75 11.5V12.5C5.75 13.464 5.7516 14.1116 5.81654 14.5946C5.87858 15.056 5.9858 15.2536 6.11613 15.3839C6.24644 15.5142 6.44394 15.6214 6.9054 15.6835C7.38843 15.7484 8.03599 15.75 9 15.75H15C15.964 15.75 16.6116 15.7484 17.0946 15.6835C17.556 15.6214 17.7535 15.5142 17.8839 15.3839C18.0142 15.2535 18.1214 15.056 18.1835 14.5946C18.2484 14.1116 18.25 13.464 18.25 12.5V11.5C18.25 10.536 18.2484 9.88843 18.1835 9.4054C18.1214 8.94394 18.0142 8.74644 17.8839 8.61613C17.7536 8.48581 17.556 8.37858 17.0946 8.31654C16.6116 8.2516 15.964 8.25 15 8.25H9C8.03599 8.25 7.38843 8.2516 6.90539 8.31654ZM8.948 6.75H15.052C15.9505 6.74997 16.6997 6.74995 17.2945 6.82991C17.9223 6.91432 18.4891 7.09999 18.9445 7.55546C19.4 8.01092 19.5857 8.57772 19.6701 9.20552C19.7501 9.8003 19.75 10.5495 19.75 11.448V12.552C19.75 13.4505 19.7501 14.1997 19.6701 14.7945C19.5857 15.4223 19.4 15.9891 18.9445 16.4445C18.4891 16.9 17.9223 17.0857 17.2945 17.1701C16.6997 17.2501 15.9505 17.25 15.052 17.25H8.94803C8.04954 17.25 7.30031 17.2501 6.70552 17.1701C6.07772 17.0857 5.51092 16.9 5.05546 16.4445C4.59999 15.9891 4.41432 15.4223 4.32991 14.7945C4.24995 14.1997 4.24997 13.4505 4.25 12.552V11.448C4.24997 10.5495 4.24995 9.8003 4.32991 9.20552C4.41432 8.57773 4.59999 8.01092 5.05547 7.55546C5.51093 7.09998 6.07773 6.91432 6.70552 6.82991C7.3003 6.74995 8.04952 6.74997 8.948 6.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.25C5.41421 1.25 5.75 1.58579 5.75 2V2.5C5.75 3.4665 6.5335 4.25 7.5 4.25H16.5C17.4665 4.25 18.25 3.46649 18.25 2.5V2C18.25 1.58579 18.5858 1.25 19 1.25C19.4142 1.25 19.75 1.58579 19.75 2V2.5C19.75 4.29493 18.2949 5.75 16.5 5.75H7.5C5.70508 5.75 4.25 4.29492 4.25 2.5V2C4.25 1.58579 4.58579 1.25 5 1.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 21.5C4.25 19.7051 5.70507 18.25 7.5 18.25H16.5C18.2949 18.25 19.75 19.7051 19.75 21.5V22C19.75 22.4142 19.4142 22.75 19 22.75C18.5858 22.75 18.25 22.4142 18.25 22V21.5C18.25 20.5335 17.4665 19.75 16.5 19.75H7.5C6.53351 19.75 5.75 20.5335 5.75 21.5V22C5.75 22.4142 5.41421 22.75 5 22.75C4.58579 22.75 4.25 22.4142 4.25 22V21.5Z"
        fill="white"
      />
    </svg>
  );
}
