import { SVGProps } from 'react';

export default function CheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5322 0.346521C13.824 0.640433 13.8224 1.1153 13.5285 1.40717L5.22079 9.65717C4.92832 9.94761 4.4563 9.94761 4.16383 9.65717L0.471521 5.9905C0.177609 5.69863 0.175955 5.22376 0.467826 4.92985C0.759697 4.63593 1.23457 4.63428 1.52848 4.92615L4.69231 8.06802L12.4715 0.342825C12.7654 0.0509545 13.2403 0.052609 13.5322 0.346521Z"
        fill="currentColor"
      />
    </svg>
  );
}
