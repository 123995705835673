import { AxiosRequestConfig } from 'axios';
import store from 'store';

import Api from 'api/Api';
import { IChildrenCommentsDto, IComment } from './dto/comments.dto';
import { PostDto } from './dto/post.dto';

class PostsMethods extends Api {
  async getAllStrikesPosts(page: number, take: number): Promise<PostDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      params: {
        conclusion: 'all',
        offset: (page - 1) * take,
        limit: take,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<PostDto[]>(
      '/v2/admin/strikes/posts',
      config,
    );

    return data;
  }

  async getTrendingPost(page: number, take: number): Promise<PostDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      params: {
        conclusion: 'images_only',
        offset: (page - 1) * take,
        limit: take,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<PostDto[]>(`/v2/feed/trending`, config);
    return data;
  }

  async getPostById(id: number) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<PostDto>(`/v2/posts/${id}`, config);
    return data;
  }

  async getCommentsPost(id: number, page: number, take: number) {
    const { deviceId } = store.getState().settings;

    const config: AxiosRequestConfig = {
      method: 'GET',
      params: {
        offset: (page - 1) * take,
        limit: take,
      },
      headers: {
        'X-Device-Id': deviceId,
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<{
      items: IComment[];
    }>(`/v2/comments/${id}`, config);
    return data.items;
  }

  async getCommentsFromParentComment({
    childrenId,
    parentId,
    page,
    take,
  }: IChildrenCommentsDto) {
    const { deviceId } = store.getState().settings;

    const config: AxiosRequestConfig = {
      method: 'GET',
      params: {
        offset: (page - 1) * take,
        limit: take,
      },
      headers: {
        'X-Device-Id': deviceId,
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<{
      items: IComment[];
    }>(`/v2/comments/${parentId}/${childrenId}`, config);
    return data.items;
  }

  async getCommentDataById(commentId: number) {
    const { deviceId } = store.getState().settings;

    const config: AxiosRequestConfig = {
      method: 'GET',
      headers: {
        'X-Device-Id': deviceId,
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<IComment>(
      `/v2/comments/${commentId}/comment/data`,
      config,
    );
    return data;
  }

  async deleteStrikePost(id: number) {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    await this.request(`/v2/admin/strikes/posts/${id}`, config);
  }

  async deletePost(id: number) {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    await this.request(`/v2/admin/post/${id}`, config);
  }

  async deleteComment(id: number) {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    await this.request(`/v2/comments/${id}`, config);
  }
}

export default new PostsMethods();
