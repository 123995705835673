import { motion } from 'framer-motion';

import Button from 'components/UI/Button';
import useResize from 'hooks/useResize';
import Modal from '../Modal';

import s from './ConfirmModal.module.scss';

interface ConfirmModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleConfirm: () => void;
}

export default function ConfirmModal({
  isOpen,
  closeModal,
  handleConfirm,
}: ConfirmModalProps) {
  const width = useResize();

  return (
    <Modal isOpen={isOpen}>
      <motion.div
        initial={width < 767 ? { transform: 'translateY(100%)' } : {}}
        animate={width < 767 ? { transform: 'translateY(0)' } : {}}
        exit={width < 767 ? { transform: 'translateY(100%)' } : {}}
        className={s.confirm}
      >
        <h4 className={s.title}>Are you sure you want to do this action?</h4>
        <div className={s.actions}>
          <Button onClick={closeModal} className={s.button}>
            No
          </Button>
          <Button variant="red" className={s.button} onClick={handleConfirm}>
            Yes
          </Button>
        </div>
      </motion.div>
    </Modal>
  );
}
