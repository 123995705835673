import { useQuery } from '@tanstack/react-query';

import PostsMethods from 'api/posts/PostsMethods';

export function useFetchPostById(id: number) {
  const { data: post, isLoading: isPostLoading } = useQuery({
    queryKey: [`post-${id}`],
    queryFn: () => PostsMethods.getPostById(id),
    enabled: !!id,
  });

  return { post, isPostLoading };
}
