import { useCallback } from 'react';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import classNames from 'classnames';

import GoBackIcon from 'assets/icons/GoBackIcon';
import logo from 'assets/images/logo.png';
import {
  NAVIGATIONS,
  STRIKESNAVIGATIONS,
} from 'constants/navigations.constants';

import s from './Aside.module.scss';

export default function Aside() {
  const { id, nickname } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = useCallback(
    (path: string) => location.pathname === path,
    [location.pathname],
  );

  return (
    <aside className={s.aside}>
      <NavLink to="/" end className={s.logo}>
        <img className={s.logo__image} alt="Logo" src={logo} />
        <p className={s.logo__text}>Control panel</p>
      </NavLink>
      {(isActive(`/post/${id}`) || isActive(`/profile/${nickname}`)) && (
        <button type="button" className={s.goBack} onClick={() => navigate(-1)}>
          <GoBackIcon /> Back
        </button>
      )}
      <nav className={s.nav}>
        <ul className={s.list}>
          {NAVIGATIONS.map((elem) => (
            <li key={elem.id}>
              <Link
                to={elem.link}
                className={classNames({
                  [s.list__item]: true,
                  [s.active]: isActive(elem.link),
                })}
              >
                {elem.icon}
                <span>{elem.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <nav className={s.nav}>
        <h3 className={s.nav__title}>Strikes</h3>
        <ul className={s.list}>
          {STRIKESNAVIGATIONS.map((elem) => (
            <li key={elem.id}>
              <Link
                to={elem.link}
                className={classNames({
                  [s.list__item]: true,
                  [s.active]: isActive(elem.link),
                })}
              >
                {elem.icon}
                <span>{elem.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
}
