import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Authorizer from 'components/helpers/Authorizer';
import Toastify from 'components/helpers/Toastify';
import store from 'store/index';
import Router from './Router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Authorizer />
          <Router />
        </BrowserRouter>
        <Toastify />
        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
