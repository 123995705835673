import { SVGProps } from 'react';

export default function GoogleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6726_33100)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16229 12.0006C5.16229 11.2385 5.28887 10.5078 5.51479 9.82247L1.56038 6.80273C0.789685 8.36753 0.355469 10.1307 0.355469 12.0006C0.355469 13.8689 0.789151 15.631 1.55878 17.1947L5.51105 14.1691C5.28726 13.487 5.16229 12.759 5.16229 12.0006Z"
          fill="#FBBC05"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1037 5.06758C13.7594 5.06758 15.2548 5.65424 16.4298 6.61424L19.848 3.20091C17.765 1.38758 15.0946 0.267578 12.1037 0.267578C7.4603 0.267578 3.46957 2.92304 1.55859 6.80304L5.513 9.82278C6.42416 7.05691 9.02145 5.06758 12.1037 5.06758Z"
          fill="#EB4335"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1037 18.9349C9.02145 18.9349 6.42416 16.9456 5.513 14.1797L1.55859 17.1989C3.46957 21.0794 7.4603 23.7349 12.1037 23.7349C14.9696 23.7349 17.7058 22.7173 19.7593 20.8106L16.0058 17.9088C14.9467 18.576 13.613 18.9349 12.1037 18.9349Z"
          fill="#34A853"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3214 12.0005C23.3214 11.3072 23.2146 10.5605 23.0543 9.86719H12.1055V14.4005H18.4077C18.0926 15.9461 17.2349 17.1344 16.0075 17.9077L19.7611 20.8096C21.9183 18.8075 23.3214 15.8251 23.3214 12.0005Z"
          fill="#4285F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_6726_33100">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
