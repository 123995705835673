import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmModal from 'components/modals/ConfirmModal';
import Button from 'components/UI/Button';
import UserInfo from 'components/UserInfo';
import { UserDto } from 'api/user/dto/user.dto';
import { useMutationHideTrendsUser } from 'hooks/mutations/useMutationHideTrendsUser';
import { useMutationSkipUser } from 'hooks/mutations/useMutationSkipUser';
import WarningIcon from 'assets/icons/WarningIcon';

import s from './StrikeUserCard.module.scss';

interface StrikeUserCardProps {
  user: UserDto;
}

export default function StrikeUserCard({ user }: StrikeUserCardProps) {
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);
  const [verdict, setVerdict] = useState({
    isSkip: false,
    isHide: false,
  });

  const { skipUser } = useMutationSkipUser();
  const { hideTrendsUser } = useMutationHideTrendsUser();

  const closeModal = () => {
    setIsModal(false);
    setVerdict({
      isSkip: false,
      isHide: false,
    });
  };

  const handleConfirm = () => {
    if (verdict.isSkip) {
      skipUser(user.id);
    }
    if (verdict.isHide) {
      hideTrendsUser(user.id);
    }
    closeModal();
  };

  return (
    <>
      <ConfirmModal
        isOpen={isModal}
        closeModal={closeModal}
        handleConfirm={handleConfirm}
      />
      <div
        className={s.container}
        onClick={() => navigate(`/profile/${user.nickname}`)}
      >
        <UserInfo user={user} />
        <div className={s.buttons}>
          <Button
            variant="green"
            className={s.button}
            onClick={(event) => {
              event.stopPropagation();
              setIsModal(true);
              setVerdict({ ...verdict, isSkip: true });
            }}
          >
            Skip
          </Button>
          <Button
            variant="red"
            className={s.button}
            onClick={(event) => {
              event.stopPropagation();
              setIsModal(true);
              setVerdict({ ...verdict, isHide: true });
            }}
          >
            Hide trends
          </Button>
        </div>
        <div className={s.statInfo}>
          <WarningIcon />
          <p>{user.strikes_count}</p>
        </div>
      </div>
    </>
  );
}
