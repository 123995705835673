import { configureStore } from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import settingSlice from './slices/settingSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    settings: settingSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
