import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import UserMethods from 'api/user/UserMethods';

export function useMutationSkipUser() {
  const queryClient = useQueryClient();

  const { mutate: skipUser } = useMutation({
    mutationKey: ['skip user'],
    mutationFn: (id: number) => UserMethods.deleteStrikeUser(id),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      toast.success('Removing a strike from a user');
    },
    onError() {
      toast.error('Something went wrong! Try again...');
    },
  });

  return { skipUser };
}
