import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import AdaptyModal from 'components/modals/AdaptyModal';
import Button from 'components/UI/Button';
import { UserDto } from 'api/user/dto/user.dto';
import { calcTime } from 'utils/formatData';
import RightArrowIcon from 'assets/icons/RightArrowIcon';
import SubscribeIcon from 'assets/icons/SubscribeIcon';

import s from './ProfileDetail.module.scss';

interface ProfileDetailProps {
  user: UserDto;
}

export default function ProfileDetail({ user }: ProfileDetailProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  const closeModal = useCallback(() => setIsModal(false), []);

  return (
    <section className={s.wrapper}>
      <AdaptyModal isOpen={isModal} closeModal={closeModal} user={user} />
      <div className={s.subscription}>
        <div className={s.subscription__text}>
          <SubscribeIcon /> Managing a paid subscription
        </div>
        {user.adapty.is_active ? (
          <div className={s.subscription__expired}>
            Valid until {calcTime(user.adapty.expires_at)}
          </div>
        ) : (
          <Button
            variant="green"
            className={s.subscription__button}
            onClick={() => setIsModal(true)}
          >
            Grant
          </Button>
        )}
      </div>
      <div className={s.detail}>
        <div
          className={classNames({
            [s.detail__header]: true,
            [s.active]: isOpen,
          })}
          onClick={toggleOpen}
        >
          <h4 className={s.detail__title}>Detail:</h4>
          <span
            className={classNames({
              [s.detail__arrow]: true,
              [s.rotate]: isOpen,
            })}
          >
            <RightArrowIcon />
          </span>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: '0px' }}
              className={s.detail__statsWrapper}
            >
              <div className={s.detail__stats}>
                <div className={s.detail__stat}>
                  <span>ID:</span>
                  <p>{user.id}</p>
                </div>
                <div className={s.detail__stat}>
                  <span>ROLE:</span>
                  <p>{user.role}</p>
                </div>
                <div className={s.detail__stat}>
                  <span>is_blocked_trends:</span>
                  <p>{user.is_trending_blocked ? 'true' : 'false'}</p>
                </div>
                <div className={s.detail__stat}>
                  <span>IS_BLOCKED:</span>
                  <p>{user.is_blocked ? 'true' : 'false'}</p>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
}
