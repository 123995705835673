import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import s from './Button.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'filled' | 'green' | 'red';
  className?: string;
}

export default function Button({
  variant = undefined,
  children,
  className = '',
  ...props
}: ButtonProps) {
  return (
    <button
      className={classNames({
        [s.button]: true,
        [s.filled]: variant === 'filled',
        [s.green]: variant === 'green',
        [s.red]: variant === 'red',
        [className]: className !== '',
      })}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
}
