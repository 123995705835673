import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

import { setAuthInitialization, setToken } from 'store/slices/authSlice';
import { useAppDispatch } from 'hooks/store';
import convertUserToUserInfo from 'utils/convertUserToUserInfo';
import { auth } from 'utils/firebase';

export default function Authorizer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return onAuthStateChanged(auth, async (currentUser) => {
      const userInfo = convertUserToUserInfo(currentUser);
      const token = await auth.currentUser?.getIdToken();

      if (userInfo && token) {
        dispatch(setAuthInitialization(true));
        dispatch(setToken(token));
        return;
      }

      navigate('/login');
    });
  }, []);

  return null;
}
