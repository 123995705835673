import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Img } from 'react-image';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import ImageComparison from 'components/ImageComparison';
import LightBoxHeader from 'components/LightBoxHeader';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';
import useGestures from 'hooks/useGestures';
import {
  HandleImagePositionEffectX,
  HandleImagePositionEffectY,
} from 'utils/handleImagePositionEffect';
import FocusIcon from 'assets/icons/FocusIcon';
import ShortArrow from 'assets/icons/ShortArrow';

import c from './LightBox.module.scss';

interface LightBoxProps {
  onCloseRequest: () => void;
  imageUrl: string;
  sourceUrl?: string;
  nextUrl?: string;
  prevUrl?: string;
  onMoveNextRequest?: () => void;
  onMovePrevRequest?: () => void;
}

function LightBox({
  onCloseRequest,
  imageUrl,
  sourceUrl = '',
  nextUrl = '',
  prevUrl = '',
  onMoveNextRequest = undefined,
  onMovePrevRequest = undefined,
}: LightBoxProps) {
  const [active, setActive] = useState<'default' | 'show' | 'close'>('default');
  const [swipe, setSwipe] = useState<'prev' | 'next' | null>(null);
  const [isCompare, setIsCompare] = useState<boolean>(false);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
  });

  const [wrapperRect, setWrapperRect] = useState<DOMRect | undefined>(
    undefined,
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageWrapperRef = useRef<HTMLDivElement>(null);

  const {
    scale,
    originX,
    originY,
    isControlsDirty,
    resetControls,
    handleWrapperPointerDown,
    handleWrapperTouchStart,
    handleWrapperWheel,
    handleChangeOrigin,
    currentGesture,
    setScale,
  } = useGestures(wrapperRect, imageSize);

  const isDragging = useMemo(
    () => currentGesture === 'dragging',
    [currentGesture],
  );

  const compareHandler = () => {
    setIsCompare(!isCompare);
  };

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    const { width, height } = event.currentTarget.getBoundingClientRect();

    if (window.innerHeight > window.innerWidth) {
      if (height > width) {
        const ratioSize = window.innerHeight / height;

        event.currentTarget.classList.add(c.isHeight);
        if (ratioSize * width > window.innerWidth) {
          event.currentTarget.classList.add(c.isHeightIsWidth);
        }
      }

      if (height <= width) {
        event.currentTarget.classList.add(c.isWidth);
      }
    } else {
      if (height >= width) {
        event.currentTarget.classList.add(c.isHeight);
      }

      if (height < width) {
        const ratioSize = window.innerWidth / width;

        event.currentTarget.classList.add(c.isWidth);
        if (height * ratioSize > window.innerHeight) {
          event.currentTarget.classList.add(c.isWidthIsHeight);
        }
      }
    }

    if (imageSize.width === 0 && imageSize.height === 0) {
      setImageSize({
        width: event.currentTarget.getBoundingClientRect().width,
        height: event.currentTarget.getBoundingClientRect().height,
      });
    }
  };

  useEffect(() => {
    setActive('show');

    const originalStyle = window.getComputedStyle(document.body).overflow;
    const originalOverflowX = window.getComputedStyle(document.body).overflowX;

    document.body.style.overflow = 'hidden';
    document.body.style.overflowX = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
      document.body.style.overflowX = originalOverflowX;
    };
  }, []);

  useEffect(() => {
    if (active === 'close') {
      setTimeout(() => {
        onCloseRequest();
      }, 500);
    }
  }, [active]);

  const handleWrapperRectChange = useCallback(
    debounce(() => {
      const rect = wrapperRef.current?.getBoundingClientRect();
      setWrapperRect(rect);
    }, 100),
    [],
  );

  useEffect(() => {
    handleWrapperRectChange();
    window.addEventListener('resize', handleWrapperRectChange);

    return () => {
      window.removeEventListener('resize', handleWrapperRectChange);
    };
  }, []);

  useEffect(() => {
    const effect = { imageWrapperRef, originY, handleChangeOrigin };
    HandleImagePositionEffectY(effect);
  }, [originY, imageWrapperRef.current, imageSize, scale]);

  useEffect(() => {
    const effect = { imageWrapperRef, originX, handleChangeOrigin };
    HandleImagePositionEffectX(effect);
  }, [originX, imageWrapperRef.current, imageSize, scale]);

  const getTransform = useMemo(() => {
    if (swipe === 'next') {
      return {
        transition: swipe ? 'all 0.3s ease' : '',
        transform: `translateX(-2000px)`,
        width: imageSize.width * scale,
        height: imageSize.height * scale,
      };
    }
    if (swipe === 'prev') {
      return {
        transition: swipe ? 'transform 0.3s ease' : '',
        transform: `translateX(2000px)`,
        width: imageSize.width * scale,
        height: imageSize.height * scale,
      };
    }
    if (imageSize.width > 0 && imageSize.height > 0) {
      return {
        transition: swipe ? 'transform 0.3s ease' : '',
        transform: `translate(${originX}px, ${originY}px)`,
        width: imageSize.width * scale,
        height: imageSize.height * scale,
      };
    }
    return {};
  }, [originX, originY, scale, imageSize, swipe]);

  useEffect(() => {
    if (imageSize) {
      const posX = window.innerWidth / 2 - imageSize.width / 2;
      const posY = window.innerHeight / 2 - imageSize.height / 2;

      handleChangeOrigin({
        x: posX,
        y: posY,
      });
    }
  }, [imageSize]);

  const handleNext = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onMoveNextRequest) {
      setSwipe('next');
      setTimeout(() => {
        onMoveNextRequest();
        setSwipe(null);
        resetControls();
      }, 300);
    }
  };

  const handlePrev = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onMovePrevRequest) {
      setSwipe('prev');
      setTimeout(() => {
        onMovePrevRequest();
        setSwipe(null);
        resetControls();
      }, 300);
    }
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        setActive('close');
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    const mobileNavigation = document.getElementById('mobileContent');
    if (mobileNavigation) {
      if (active === 'show') {
        mobileNavigation.style.zIndex = 'auto';
      } else {
        mobileNavigation.style.zIndex = '1000';
      }
    }
  }, [active]);

  return (
    <div
      className={classNames({
        [c.wrapper]: true,
        [c.active]: active === 'show',
      })}
      onClick={() => setActive('close')}
    >
      {prevUrl && (
        <button type="button" className={c.prevArrow} onClick={handlePrev}>
          <ShortArrow width={30} height={30} />
        </button>
      )}
      <LightBoxHeader
        imageUrl={imageUrl}
        sourceUrl={sourceUrl}
        setActive={setActive}
        scale={scale}
        setScale={setScale}
        isCompare={isCompare}
        compareHandler={compareHandler}
        resetControls={resetControls}
      />
      <div
        className={c.body}
        ref={wrapperRef}
        onWheel={handleWrapperWheel}
        onPointerDown={handleWrapperPointerDown}
        onTouchStart={handleWrapperTouchStart}
      >
        {prevUrl && (
          <div
            className={c.prevImageWrapper}
            style={{
              transition: swipe === 'prev' ? 'all 0.3s ease' : '',
              right: swipe === 'prev' ? 0 : '100%',
            }}
            onClick={(event) => event.stopPropagation()}
          >
            <Img
              src={prevUrl || 'defaultImageUrl'}
              className={c.image}
              loader={
                <div className={c.loaderWrapper}>
                  <Loader />
                </div>
              }
            />
          </div>
        )}

        {!isCompare ? (
          <>
            <div
              className={c.imageWrapper}
              style={getTransform}
              ref={imageWrapperRef}
              onClick={(event) => event.stopPropagation()}
            >
              <Img
                src={imageUrl || 'defaultImageUrl'}
                className={c.image}
                loader={
                  <div className={c.loaderWrapper}>
                    <Loader />
                  </div>
                }
                onLoad={handleImageLoad}
              />
            </div>
            <Button
              disabled={!isControlsDirty || isDragging}
              className={c.resetButton}
              onClick={(event) => {
                event.stopPropagation();
                resetControls();
              }}
            >
              <FocusIcon style={{ fill: 'currentColor' }} />
            </Button>
          </>
        ) : (
          <div
            className={c.imageCompareWrapper}
            onClick={(event) => event.stopPropagation()}
          >
            <ImageComparison
              imageSize={imageSize}
              imageUrl={imageUrl}
              sourceUrl={sourceUrl}
            />
          </div>
        )}
        {nextUrl && (
          <div
            className={c.nextImageWrapper}
            style={{
              transition: swipe === 'next' ? 'all 0.3s ease' : '',
              left: swipe === 'next' ? 0 : '100%',
            }}
          >
            <Img
              src={nextUrl || 'defaultImageUrl'}
              className={c.image}
              loader={
                <div className={c.loaderWrapper}>
                  <Loader />
                </div>
              }
            />
          </div>
        )}
      </div>
      {prevUrl && (
        <button type="button" className={c.nextArrow} onClick={handleNext}>
          <ShortArrow width={30} height={30} />
        </button>
      )}
    </div>
  );
}

export default LightBox;
