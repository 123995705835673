import { useCallback, useMemo } from 'react';
import { InView } from 'react-intersection-observer';

import Loader from 'components/UI/Loader';
import PostCard from 'pages/StrikePostsPage/components/PostCard';
import { useFetchInfiniteProfilePosts } from 'hooks/query/useFetchInfiniteProfilePosts';

import s from './ProfilePosts.module.scss';

interface ProfilePostsProps {
  id: number;
}

export default function ProfilePosts({ id }: ProfilePostsProps) {
  const {
    data: infinitePostsData,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useFetchInfiniteProfilePosts(id);

  const userPosts = useMemo(() => {
    if (infinitePostsData === undefined) return [];
    return infinitePostsData.pages.flatMap((p) => p);
  }, [infinitePostsData]);

  const onScrollToEnd = useCallback(() => {
    if (isLoading) return;
    fetchNextPage();
  }, [isLoading, fetchNextPage]);

  if (isLoading) {
    return (
      <div className="loaderWrapper">
        <Loader />
      </div>
    );
  }

  return userPosts ? (
    <>
      <section className={s.posts}>
        {userPosts.map((post) => (
          <PostCard key={post.id} post={post} />
        ))}
      </section>
      {!isLoading && !isFetchingNextPage && hasNextPage && (
        <InView as="div" onChange={(inView) => inView && onScrollToEnd()} />
      )}
      {isFetchingNextPage && (
        <div className={s.loaderWrapper}>
          <Loader />
        </div>
      )}
    </>
  ) : null;
}
