import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import PostsMethods from 'api/posts/PostsMethods';

interface Props {
  postId?: number;
  childrenId?: number;
}

export function useMutationRemoveComment({ postId, childrenId }: Props) {
  const queryClient = useQueryClient();

  const { mutate: deletePost } = useMutation({
    mutationKey: ['remove post'],
    mutationFn: (id: number) => PostsMethods.deleteComment(id),
    onSuccess: () => {
      if (postId) {
        queryClient.invalidateQueries({
          queryKey: [`infinite-comments-post-${postId}`],
        });
      }
      if (childrenId) {
        queryClient.invalidateQueries({
          queryKey: [`infinite-childrenComments-${childrenId}`],
        });
      }
      toast.success('Deleting a post');
    },
    onError: () => {
      toast.error('Something went wrong! Try again...');
    },
  });

  return { deletePost };
}
