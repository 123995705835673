import { useQuery } from '@tanstack/react-query';

import UserMethods from 'api/user/UserMethods';

export function useFetchUserByNickname(nickname: string | undefined) {
  const { data: user, isLoading: isLoadingUser } = useQuery({
    queryKey: [nickname],
    queryFn: () => UserMethods.getByNickname(nickname!),
    enabled: !!nickname,
  });

  return { user, isLoadingUser };
}
