import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import UserMethods from 'api/user/UserMethods';

export function useMutationGenerateAvatar() {
  const { mutate: generateNewAvatar } = useMutation({
    mutationKey: ['generate new avatar'],
    mutationFn: (id: number) => UserMethods.postGenerateNewAvatar(id),
    onSuccess() {
      toast.success('Successfully! User picture will be updated soon.');
    },
    onError() {
      toast.error('Something went wrong! Try again...');
    },
  });

  return { generateNewAvatar };
}
