import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import RightArrowIcon from 'assets/icons/RightArrowIcon';

import s from './MobileHeader.module.scss';

interface MobileHeaderProps {
  title: string;
  rightButton?: ReactNode;
  isArrow?: boolean;
}

export default function MobileHeader({
  title,
  rightButton,
  isArrow = undefined,
}: MobileHeaderProps) {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <header className={s.header}>
      <div>
        {isArrow && (
          <button className={s.arrowWrapper} onClick={goBack}>
            <RightArrowIcon />
          </button>
        )}
      </div>
      <div className={s.title}>{title}</div>
      <div className={s.actions}>{rightButton}</div>
    </header>
  );
}
