import { useCallback, useMemo } from 'react';
import { InView } from 'react-intersection-observer';

import MobileHeader from 'components/MobileHeader';
import Loader from 'components/UI/Loader';
import PostCard from 'pages/StrikePostsPage/components/PostCard';
import { useFetchInfiniteTrendingPosts } from 'hooks/query/useFetchInfiniteTrendingPosts';

import s from './TrendingPage.module.scss';

export default function TrendingPage() {
  const {
    data: infinitePostsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useFetchInfiniteTrendingPosts();

  const posts = useMemo(() => {
    if (infinitePostsData === undefined) return [];
    return infinitePostsData.pages.flatMap((p) => p);
  }, [infinitePostsData]);

  const onScrollToEnd = useCallback(() => {
    if (isLoading) return;
    fetchNextPage();
  }, [isLoading, fetchNextPage]);

  if (isLoading) {
    return (
      <div className="loaderWrapper">
        <Loader />
      </div>
    );
  }

  return (
    <section className={s.trending}>
      <MobileHeader title="Trending" />
      <h2 className={s.title}>Trending</h2>
      <div className={s.posts}>
        {posts.length > 0
          ? posts.map((post) => <PostCard key={post.id} post={post} />)
          : null}
      </div>
      {!isLoading && !isFetchingNextPage && hasNextPage && (
        <InView as="div" onChange={(inView) => inView && onScrollToEnd()} />
      )}
      {isFetchingNextPage && (
        <div className={s.loaderWrapper}>
          <Loader />
        </div>
      )}
    </section>
  );
}
