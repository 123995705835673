import { Link } from 'react-router-dom';

import MobileHeader from 'components/MobileHeader';
import RightArrowIcon from 'assets/icons/RightArrowIcon';
import { STRIKENAVIGATION } from 'constants/strike.constants';

import s from './StrikePage.module.scss';

export default function StrikePage() {
  return (
    <section className={s.strike}>
      <MobileHeader title="Strike" />
      <ul className={s.list}>
        {STRIKENAVIGATION.map((elem) => (
          <li key={elem.id}>
            <Link className={s.list__item} to={elem.link}>
              <p className={s.list__text}>{elem.title}</p>
              <RightArrowIcon />
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
}
