import classNames from 'classnames';

import s from './Category.module.scss';

interface CategoryProps {
  variant?: 'filled';
  className?: string;
  children: string;
}

export default function Category({
  variant = undefined,
  className = '',
  children,
  ...props
}: CategoryProps) {
  return (
    <div
      className={classNames({
        [s.category]: true,
        [s.filled]: variant === 'filled',
        [className]: className !== '',
      })}
      {...props}
    >
      {children}
    </div>
  );
}
