import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import UserMethods from 'api/user/UserMethods';

export function useMutationHideTrendsUser(nickname?: string) {
  const queryClient = useQueryClient();

  const { mutate: hideTrendsUser } = useMutation({
    mutationKey: ['hide trends user'],
    mutationFn: (id: number) => UserMethods.postHideTrendsUser(id),
    onSuccess: () => {
      if (nickname) {
        queryClient.invalidateQueries({
          queryKey: [nickname],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['users'],
        });
      }
      toast.success('Blocking a user in a feed!');
    },
    onError: () => {
      toast.error('Something went wrong! Try again...');
    },
  });

  return { hideTrendsUser };
}
