import React, { useCallback, useEffect, useMemo, useState } from 'react';
import throttle from 'lodash.throttle';

type Gesture = 'panning' | 'pinching' | 'dragging';

const SCALE_LOWER_BOUND = 0.8; // Нижняя граница масштабирования
const SCALE_UPPER_BOUND = 10; // Верхняя граница масштабирования
const PINCH_COEFFICIENT = 5; // Коэффициент масштабирования при жесте пальцами
const WHEEL_COEFFICIENT = 0.2; // Коэффициент масштабирования при скролле
const POINTER_THROTTLE = 10; // Время троттлинга для событий указателя
const TOUCH_THROTTLE = 10; // Время троттлинга для событий касаний
const WHEEL_THROTTLE = 100; // Время троттлинга для событий скроллинга
const WRAPPER_BORDER_RADIUS = 15; // border-radius ограничивающего контейнера

const useGestures = (
  wrapperRect: DOMRect | undefined,
  size?: { width: number; height: number } | null,
  imageWrapperRef?: React.RefObject<HTMLDivElement> | undefined,
) => {
  // Позиция слайдера
  const [sliderX, setSliderX] = useState(WRAPPER_BORDER_RADIUS);
  // Коэффициент масштабирования
  const [scale, setScale] = useState(1);
  // Сдвиг масштабирования
  const [originX, setOriginX] = useState(0);
  const [originY, setOriginY] = useState(0);
  // Координата старта перемещения
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  // Координата центра прямоугольника между двумя пальцами
  const [centerX, setCenterX] = useState(0);
  const [centerY, setCenterY] = useState(0);
  // Стартовое расстояние между двумя пальцами (диагональ прямоугольника)
  const [startHypot, setStartHypot] = useState(0);
  // Предыдущее значение количества пальцев, касающихся экрана
  const [prevTouchesLength, setPrevTouchesLength] = useState(0);

  const [currentGesture, setCurrentGesture] = useState<Gesture | null>(null);

  const isControlsDirty = useMemo(() => {
    if (size) {
      const checkX = window.innerWidth / 2 - size.width / 2;
      const checkY = window.innerHeight / 2 - size.height / 2;
      return scale !== 1 || originX !== checkX || originY !== checkY;
    }
    return scale !== 1 || originX !== 0 || originY !== 0;
  }, [scale, originX, originY]);

  const resetControls = useCallback(() => {
    setScale(1);
    setOriginX(0);
    setOriginY(0);
    setStartX(0);
    setStartY(0);

    if (size) {
      const posX = window.innerWidth / 2 - size.width / 2;
      const posY = window.innerHeight / 2 - size.height / 2;

      setOriginX(posX);
      setOriginY(posY);
    }
  }, [size]);

  const changeScale = useCallback(
    (scaleDiff: number, customCenterX?: number, customCenterY?: number) => {
      const newScale = scale + scaleDiff;
      if (newScale < SCALE_LOWER_BOUND) {
        setScale(SCALE_LOWER_BOUND);
      } else if (newScale > SCALE_UPPER_BOUND) {
        setScale(SCALE_UPPER_BOUND);
      } else {
        const cx = customCenterX || centerX;
        const cy = customCenterY || centerY;
        const xs = (cx - originX) / scale;
        const ys = (cy - originY) / scale;
        const newOriginX = cx - xs * newScale;
        const newOriginY = cy - ys * newScale;
        setOriginX(newOriginX);
        setOriginY(newOriginY);
        setScale(newScale);
      }
    },
    [scale, originX, originY, centerX, centerY],
  );

  const startPanning = useCallback(
    (pageX: number, pageY: number) => {
      if (wrapperRect === undefined) return;

      setStartX(pageX - wrapperRect.x - originX);
      setStartY(pageY - wrapperRect.y - originY);
      setCurrentGesture('panning');
    },
    [wrapperRect, originX, originY],
  );

  const panning = useCallback(
    (pageX: number, pageY: number) => {
      if (currentGesture !== 'panning') return;
      if (wrapperRect === undefined) return;

      setOriginX(pageX - wrapperRect.x - startX);
      setOriginY(pageY - wrapperRect.y - startY);
    },
    [currentGesture, wrapperRect, startX, startY],
  );

  const startPinching = useCallback(
    (firstX: number, firstY: number, secondX: number, secondY: number) => {
      if (wrapperRect === undefined) return;

      const xDiff = firstX - secondX;
      const yDiff = firstY - secondY;
      const hypot = Math.hypot(xDiff, yDiff);
      const newCenterX = (firstX + secondX) / 2 - wrapperRect.x;
      const newCenterY = (firstY + secondY) / 2 - wrapperRect.y;
      setStartHypot(hypot);
      setCenterX(newCenterX);
      setCenterY(newCenterY);
      setCurrentGesture('pinching');
    },
    [wrapperRect],
  );

  const pinching = useCallback(
    (firstX: number, firstY: number, secondX: number, secondY: number) => {
      if (currentGesture !== 'pinching') return;
      if (wrapperRect === undefined) return;

      const xDiff = firstX - secondX;
      const yDiff = firstY - secondY;
      const hypot = Math.hypot(xDiff, yDiff);
      const hypotDiff = hypot - startHypot;
      const rectHypot = Math.hypot(wrapperRect.width, wrapperRect.height);
      const scaleDiff = (PINCH_COEFFICIENT * hypotDiff) / rectHypot;
      changeScale(scaleDiff);
    },
    [currentGesture, wrapperRect, startHypot, changeScale],
  );

  const startDragging = useCallback(() => {
    setCurrentGesture('dragging');
  }, []);

  const wheeling = useCallback(
    (pageX: number, pageY: number, deltaY: number) => {
      if (currentGesture !== null) return;
      if (wrapperRect === undefined) return;

      const direction = -Math.sign(deltaY);
      const scaleDiff = direction * WHEEL_COEFFICIENT;
      const customCenterX = pageX - wrapperRect.x;
      const customCenterY = pageY - wrapperRect.y;
      changeScale(scaleDiff, customCenterX, customCenterY);
    },
    [currentGesture, wrapperRect, changeScale],
  );

  const dragging = useCallback(
    (pageX: number) => {
      if (currentGesture !== 'dragging') return;
      if (!imageWrapperRef?.current) return;

      // Границы перемещения слайдера
      const { width, x } = imageWrapperRef.current.getBoundingClientRect();
      const leftBound = WRAPPER_BORDER_RADIUS;
      const rightBound = width - WRAPPER_BORDER_RADIUS;
      const newX = pageX - x;
      if (newX < leftBound) {
        setSliderX(leftBound);
      } else if (newX > rightBound) {
        setSliderX(rightBound);
      } else {
        setSliderX(newX);
      }
    },
    [currentGesture, wheeling, imageWrapperRef?.current],
  );

  const handleWrapperTouchesLengthChange = useCallback(
    (e: TouchEvent | React.TouchEvent<HTMLDivElement>) => {
      const { length } = e.touches;
      setPrevTouchesLength(length);

      // Не даёт использовать panning сразу после pinching,
      // так как это вызывает "прыжок" картинки
      // TODO: исправить ошибку, которая вызывает прыжок, и убрать этот хак
      if (prevTouchesLength > 1) return;

      if (length === 1) {
        const { pageX, pageY } = e.touches[0];
        startPanning(pageX, pageY);
      } else if (length === 2) {
        const { pageX: firstX, pageY: firstY } = e.touches[0];
        const { pageX: secondX, pageY: secondY } = e.touches[1];
        startPinching(firstX, firstY, secondX, secondY);
      } else {
        setCurrentGesture(null);
      }
    },
    [prevTouchesLength, startPanning, startPinching],
  );

  const handleSliderTouchesLengthChange = useCallback(
    (e: TouchEvent | React.TouchEvent<HTMLDivElement>) => {
      const { length } = e.touches;

      if (length === 1) {
        startDragging();
      } else {
        setCurrentGesture(null);
      }
    },
    [startDragging],
  );

  const handleWrapperPointerDown = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (e.pointerType !== 'mouse') return;
      if (e.button !== 0) return;
      e.preventDefault();
      const { pageX, pageY } = e;
      startPanning(pageX, pageY);
    },
    [startPanning],
  );

  const handleWrapperPointerUp = useCallback((e: PointerEvent) => {
    if (e.pointerType !== 'mouse') return;
    if (e.button !== 0) return;
    setCurrentGesture(null);
  }, []);

  const handleWrapperPointerMove = useCallback(
    throttle((e: PointerEvent) => {
      if (e.pointerType !== 'mouse') return;
      e.preventDefault();
      const { pageX, pageY } = e;
      panning(pageX, pageY);
    }, POINTER_THROTTLE),
    [panning],
  );

  const handleWrapperTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      e.preventDefault();
      handleWrapperTouchesLengthChange(e);
    },
    [handleWrapperTouchesLengthChange],
  );

  const handleWrapperTouchEnd = useCallback(
    (e: TouchEvent) => {
      handleWrapperTouchesLengthChange(e);
    },
    [handleWrapperTouchesLengthChange],
  );

  const handleWrapperTouchMove = useCallback(
    throttle((e: TouchEvent) => {
      e.preventDefault();
      const { length } = e.touches;
      if (length === 1) {
        const { pageX, pageY } = e.touches[0];
        panning(pageX, pageY);
      }
      if (length === 2) {
        const { pageX: firstX, pageY: firstY } = e.touches[0];
        const { pageX: secondX, pageY: secondY } = e.touches[1];
        pinching(firstX, firstY, secondX, secondY);
      }
    }, TOUCH_THROTTLE),
    [panning, pinching],
  );

  const handleWrapperWheel = useCallback(
    throttle((e: React.WheelEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const { pageX, pageY, deltaY } = e;
      wheeling(pageX, pageY, deltaY);
    }, WHEEL_THROTTLE),
    [wheeling],
  );

  const handleSliderPointerDown = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (e.pointerType !== 'mouse') return;
      if (e.button !== 0) return;
      e.preventDefault();
      e.stopPropagation();
      startDragging();
    },
    [startDragging],
  );

  const handleSliderPointerUp = useCallback((e: PointerEvent) => {
    if (e.pointerType !== 'mouse') return;
    if (e.button !== 0) return;
    e.preventDefault();
    setCurrentGesture(null);
  }, []);

  const handleSliderPointerMove = useCallback(
    throttle((e: PointerEvent) => {
      if (e.pointerType !== 'mouse') return;
      e.preventDefault();
      e.stopPropagation();
      const { pageX } = e;
      dragging(pageX);
    }, POINTER_THROTTLE),
    [dragging],
  );

  const handleSliderTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleSliderTouchesLengthChange(e);
    },
    [handleSliderTouchesLengthChange],
  );

  const handleSliderTouchEnd = useCallback(
    (e: TouchEvent) => {
      handleSliderTouchesLengthChange(e);
    },
    [handleSliderTouchesLengthChange],
  );

  const handleSliderTouchMove = useCallback(
    throttle((e: TouchEvent) => {
      e.preventDefault();
      const { pageX } = e.touches[0];
      dragging(pageX);
    }, TOUCH_THROTTLE),
    [dragging],
  );

  useEffect(() => {
    // Начальная позиция слайдера (половина ширины ограничивающего контейнера)
    if (!imageWrapperRef?.current) return;

    const { width } = imageWrapperRef.current.getBoundingClientRect();
    setSliderX(width * 0.5);
  }, [imageWrapperRef?.current, scale]);

  useEffect(() => {
    const addPanningEventListeners = () => {
      document.addEventListener('pointermove', handleWrapperPointerMove);
      document.addEventListener('pointerup', handleWrapperPointerUp);
      document.addEventListener('touchmove', handleWrapperTouchMove);
      document.addEventListener('touchend', handleWrapperTouchEnd);
    };

    const addPinchingEventListeners = () => {
      document.addEventListener('touchmove', handleWrapperTouchMove);
      document.addEventListener('touchend', handleWrapperTouchEnd);
    };

    const addDraggingEventListeners = () => {
      document.addEventListener('pointermove', handleSliderPointerMove);
      document.addEventListener('pointerup', handleSliderPointerUp);
      document.addEventListener('touchmove', handleSliderTouchMove);
      document.addEventListener('touchend', handleSliderTouchEnd);
    };

    const removeAllEventListeners = () => {
      // panning и pinching для ограничивающего контейнера
      document.removeEventListener('pointermove', handleWrapperPointerMove);
      document.removeEventListener('pointerup', handleWrapperPointerUp);
      document.removeEventListener('touchmove', handleWrapperTouchMove);
      document.removeEventListener('touchend', handleWrapperTouchEnd);
      // dragging для слайдера
      document.removeEventListener('pointermove', handleSliderPointerMove);
      document.removeEventListener('pointerup', handleSliderPointerUp);
      document.removeEventListener('touchmove', handleSliderTouchMove);
      document.removeEventListener('touchend', handleSliderTouchEnd);
    };

    if (currentGesture) {
      if (currentGesture === 'panning') addPanningEventListeners();
      if (currentGesture === 'pinching') addPinchingEventListeners();
      if (currentGesture === 'dragging') addDraggingEventListeners();
    } else {
      removeAllEventListeners();
    }

    return () => {
      removeAllEventListeners();
    };
  }, [currentGesture]);

  const handleChangeOrigin = useCallback(
    ({ x, y }: { x?: number; y?: number }) => {
      if (x !== undefined) {
        setOriginX(x);
      }

      if (y !== undefined) {
        setOriginY(y);
      }
    },
    [],
  );

  return {
    sliderX,
    scale,
    originX,
    originY,
    currentGesture,
    isControlsDirty,
    resetControls,
    handleWrapperPointerDown,
    handleWrapperTouchStart,
    handleWrapperWheel,
    handleSliderPointerDown,
    handleSliderTouchStart,
    handleChangeOrigin,
    setScale,
  };
};

export default useGestures;
