import { useCallback, useMemo } from 'react';
import { InView } from 'react-intersection-observer';

import Loader from 'components/UI/Loader';
import { IPost, PostDto } from 'api/posts/dto/post.dto';
import { useFetchInfiniteCommentsPost } from 'hooks/query/useFetchInfiniteCommentsPost';
import CommentCard from '../CommentCard';

import s from './PostComments.module.scss';

interface PostCommentsProps {
  post: PostDto;
}

export default function PostComments({ post }: PostCommentsProps) {
  const {
    data: infiniteCommentsPostData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchInfiniteCommentsPost(post.id);

  const comments = useMemo(() => {
    if (infiniteCommentsPostData === undefined) return [];
    return infiniteCommentsPostData.pages.flatMap((p) => p);
  }, [infiniteCommentsPostData]);

  const onScrollToEnd = useCallback(() => {
    if (isLoading) return;
    fetchNextPage();
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="loaderWrapper">
        <Loader />
      </div>
    );
  }

  return (
    <section className={s.postComments}>
      <h2>Comments</h2>
      {comments.length > 0 ? (
        <div className={s.comments}>
          {comments.map((comment) => {
            if (comment.is_deleted) return null;

            return (
              <CommentCard key={comment.id} comment={comment} post={post} />
            );
          })}
        </div>
      ) : (
        <div className={s.nothing}>No Comments</div>
      )}
      {!isLoading && !isFetchingNextPage && hasNextPage && (
        <InView as="div" onChange={(inView) => inView && onScrollToEnd()} />
      )}
      {isFetchingNextPage && (
        <div className={s.loaderWrapper}>
          <Loader />
        </div>
      )}
    </section>
  );
}
