import { useInfiniteQuery } from '@tanstack/react-query';

import PostsMethods from 'api/posts/PostsMethods';

const take = 3;

export function useFetchInfiniteCommentsPost(id: number) {
  return useInfiniteQuery({
    queryKey: [`infinite-comments-post-${id}`],
    queryFn: ({ pageParam }) => {
      const page = typeof pageParam === 'number' ? pageParam : 1;
      return PostsMethods.getCommentsPost(id, page, take);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage || lastPage.length < take) return undefined;
      return allPages.length + 1;
    },
    initialPageParam: 1,
    enabled: !!id,
  });
}
