import { AxiosRequestConfig } from 'axios';

import { AdaptyData } from 'components/modals/AdaptyModal/AdaptyModal';
import Api from 'api/Api';
import { PostDto } from 'api/posts/dto/post.dto';
import { UserDto } from './dto/user.dto';

class UserMethods extends Api {
  async getProfile(): Promise<UserDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<UserDto>('/v2/profile', config);
    return data;
  }

  async getByNickname(nicname: string): Promise<UserDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<UserDto>(`/v2/users/${nicname}`, config);

    return data;
  }

  async getPostsByUserId(
    id: number,
    page: number,
    take: number,
  ): Promise<PostDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      params: {
        conclusion: 'all',
        offset: (page - 1) * take,
        limit: take,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<PostDto[]>(`/v2/users/${id}/posts`, config);

    return data;
  }

  async deleteStrikeUser(id: number) {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    await this.request(`/v2/admin/strikes/users/${id}`, config);
  }

  async postHideTrendsUser(id: number) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    await this.request(`/v2/admin/user/${id}/trending/block`, config);
  }

  async postShowTrendsUser(id: number) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    await this.request(`/v2/admin/user/${id}/trending/unblock`, config);
  }

  async postBlockedUser(id: number) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    await this.request(`/v2/admin/user/${id}/block`, config);
  }
  async postUnblockedUser(id: number) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    await this.request(`/v2/admin/user/${id}/unblock`, config);
  }

  async postIssueAdapty(id: number, body: AdaptyData) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      data: body,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    return this.request(`/v2/adapty/premium/${id}`, config);
  }

  async postGenerateNewAvatar(id: number) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    await this.request(`/v2/users/${id}/avatar`, config);
  }

  async getAllStrikesUsers(): Promise<UserDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };

    const data = await this.request<UserDto[]>(
      '/v2/admin/strikes/users',
      config,
    );

    return data;
  }
}

export default new UserMethods();
