import { useState } from 'react';

import AuthForm from 'components/forms/AuthForm';
import Button from 'components/UI/Button';
import { AuthTabsType } from 'types/Auth';

import s from './AuthPage.module.scss';

export default function AuthPage() {
  const [tabs, setTabs] = useState<AuthTabsType>('login');

  const handleTabs = (value: AuthTabsType) => {
    setTabs(value);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={s.tabs}>
          {/* <Button
            variant={tabs === 'register' ? 'filled' : undefined}
            onClick={() => handleTabs('register')}
          >
            Регистрация
          </Button> */}
          <Button
            variant={tabs === 'login' ? 'filled' : undefined}
            onClick={() => handleTabs('login')}
          >
            Вход
          </Button>
        </div>
        <AuthForm tabs={tabs} />
      </div>
    </div>
  );
}
