import { SVGProps } from 'react';

export default function ReplyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.499 6.24852C14.691 6.24852 14.883 6.32052 15.03 6.46752L20.028 11.4655C20.04 11.4775 20.052 11.4895 20.064 11.5045C20.064 11.5045 20.064 11.5075 20.067 11.5075C20.118 11.5675 20.16 11.6335 20.19 11.7025C20.19 11.7025 20.19 11.7025 20.19 11.7055C20.229 11.7925 20.25 11.8885 20.25 11.9905L20.25 11.9935L20.25 11.9965L20.25 11.9995L20.25 12.0025C20.25 12.1045 20.229 12.2005 20.19 12.2875C20.19 12.2875 20.19 12.2875 20.19 12.2905C20.16 12.3595 20.118 12.4255 20.067 12.4855C20.067 12.4855 20.067 12.4885 20.064 12.4885C20.052 12.5005 20.04 12.5155 20.028 12.5275L15.03 17.5285C14.736 17.8225 14.262 17.8225 13.968 17.5285C13.674 17.2345 13.674 16.7605 13.968 16.4665L17.688 12.7465L9.498 12.7465C8.775 12.7465 7.689 12.9715 6.81 13.6045C5.772 14.3515 5.247 15.4915 5.247 16.9945C5.247 17.4085 4.911 17.7445 4.497 17.7445C4.083 17.7445 3.747 17.4085 3.747 16.9945C3.747 14.4865 4.935 13.1035 5.934 12.3865C7.404 11.3275 9.033 11.2465 9.498 11.2465L17.688 11.2465L13.968 7.52652C13.674 7.23252 13.674 6.75852 13.968 6.46452C14.115 6.32352 14.307 6.24852 14.499 6.24852Z"
        fill="currentColor"
      />
    </svg>
  );
}
