import MobileHeader from 'components/MobileHeader';
import { DASHBOARDLIST } from 'constants/dashboard.constants';
import DashboardCard from './components/DashboardCard';

import s from './DashboardPage.module.scss';

export default function DashboardPage() {
  return (
    <div className={s.container}>
      <MobileHeader title="Dashboard" />
      {DASHBOARDLIST.map((elem) => (
        <DashboardCard
          img={elem.img}
          key={elem.id}
          link={elem.link}
          title={elem.title}
        />
      ))}
    </div>
  );
}
