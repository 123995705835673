import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';

import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { UserDto } from 'api/user/dto/user.dto';
import { useMutationGrantAdapty } from 'hooks/mutations/useMutationGrantAdapty';
import useResize from 'hooks/useResize';
import Modal from '../Modal';

import s from './AdaptyModal.module.scss';

interface AdaptyModalProps {
  isOpen: boolean;
  closeModal: () => void;
  user: UserDto;
}

export interface AdaptyData {
  days: string;
}

export default function AdaptyModal({
  closeModal,
  isOpen,
  user,
}: AdaptyModalProps) {
  const { register, handleSubmit, reset } = useForm<AdaptyData>();
  const width = useResize();

  const { adaptyGrant } = useMutationGrantAdapty(user.nickname);

  const onClose = () => {
    closeModal();
    reset({
      days: '',
    });
  };

  const onSubmit = (data: AdaptyData) => {
    adaptyGrant({ userId: user.id, data });
    onClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <motion.div
        initial={width < 767 ? { transform: 'translateY(100%)' } : {}}
        animate={width < 767 ? { transform: 'translateY(0)' } : {}}
        exit={width < 767 ? { transform: 'translateY(100%)' } : {}}
        className={s.adapty}
      >
        <h4 className={s.title}>Issue a premium</h4>
        <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
          <Input
            placeholder="Days"
            type="number"
            {...register('days', { required: true })}
          />
          <div className={s.form__actions}>
            <Button variant="red" onClick={onClose} className={s.button}>
              Cancel
            </Button>
            <Button variant="green" type="submit" className={s.button}>
              Grant
            </Button>
          </div>
        </form>
      </motion.div>
    </Modal>
  );
}
