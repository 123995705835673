import { useCallback, useState } from 'react';

import ConfirmModal from 'components/modals/ConfirmModal';
import Button from 'components/UI/Button';
import { UserDto } from 'api/user/dto/user.dto';
import { useMutationHideTrendsUser } from 'hooks/mutations/useMutationHideTrendsUser';
import { useMutationShowTrendsUser } from 'hooks/mutations/useMutationShowTrendsUser';
import { useMutationUserBlocked } from 'hooks/mutations/useMutationUserBlocked';
import { useMutationUserUnblocked } from 'hooks/mutations/useMutationUserUnblocked';

import s from './ProfileActions.module.scss';

interface ProfileActionsProps {
  user: UserDto;
}

export default function ProfileActions({ user }: ProfileActionsProps) {
  const [isModal, setIsModal] = useState(false);
  const [verdict, setVerdict] = useState({
    isHideTrends: false,
    isShowTrend: false,
    isBlocked: false,
    isUnblocked: false,
  });

  const { blockedUser } = useMutationUserBlocked(user.nickname);
  const { unblockedUser } = useMutationUserUnblocked(user.nickname);
  const { hideTrendsUser } = useMutationHideTrendsUser(user.nickname);
  const { showTrendsUser } = useMutationShowTrendsUser(user.nickname);

  const closeModal = useCallback(() => {
    setIsModal(false);
    setVerdict({
      isHideTrends: false,
      isShowTrend: false,
      isBlocked: false,
      isUnblocked: false,
    });
  }, []);

  const handleConfirm = useCallback(() => {
    if (verdict.isBlocked) {
      blockedUser(user.id);
    }
    if (verdict.isHideTrends) {
      hideTrendsUser(user.id);
    }
    if (verdict.isShowTrend) {
      showTrendsUser(user.id);
    }
    if (verdict.isUnblocked) {
      unblockedUser(user.id);
    }
    closeModal();
  }, [
    verdict,
    closeModal,
    blockedUser,
    hideTrendsUser,
    showTrendsUser,
    unblockedUser,
    user.id,
  ]);

  return (
    <section className={s.actions}>
      <ConfirmModal
        handleConfirm={handleConfirm}
        closeModal={closeModal}
        isOpen={isModal}
      />
      {user.is_trending_blocked ? (
        <Button
          variant="green"
          className={s.button}
          onClick={() => {
            setIsModal(true);
            setVerdict((prev) => ({ ...prev, isShowTrend: true }));
          }}
        >
          Show trends
        </Button>
      ) : (
        <Button
          variant="red"
          className={s.button}
          onClick={() => {
            setIsModal(true);
            setVerdict((prev) => ({ ...prev, isHideTrends: true }));
          }}
        >
          Hide trends
        </Button>
      )}
      {user.is_blocked ? (
        <Button
          variant="green"
          className={s.button}
          onClick={() => {
            setIsModal(true);
            setVerdict((prev) => ({ ...prev, isUnblocked: true }));
          }}
        >
          Unblock
        </Button>
      ) : (
        <Button
          variant="red"
          className={s.button}
          onClick={() => {
            setIsModal(true);
            setVerdict((prev) => ({ ...prev, isBlocked: true }));
          }}
        >
          Block
        </Button>
      )}
    </section>
  );
}
