import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import UserMethods from 'api/user/UserMethods';

export function useMutationUserBlocked(nickname: string) {
  const queryClient = useQueryClient();

  const { mutate: blockedUser } = useMutation({
    mutationKey: ['blocked user'],
    mutationFn: (id: number) => UserMethods.postBlockedUser(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [nickname],
      });
      toast.success('Blocking user!');
    },
    onError: () => {
      toast.error('Something went wrong! Try again...');
    },
  });
  return { blockedUser };
}
