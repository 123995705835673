import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

const useSave = (imageUrl: string | undefined) => {
  const [isSaved, setIsSaved] = useState(false);

  const handleSave = useCallback(() => {
    if (imageUrl == null) return;

    const fileName = imageUrl.split('/')[imageUrl.split('/').length - 1];

    try {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, fileName);
        })
        .catch(() => {
          toast.error('Error download!');
        });
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 3000);
    } catch (e) {
      toast.error('Error download!');
    }
  }, [imageUrl]);

  return { isSaved, handleSave };
};

export default useSave;
