import { ToastContainer } from 'react-toastify';

export default function Toastify() {
  return (
    <ToastContainer
      position="bottom-right"
      icon={false}
      theme="colored"
      toastStyle={{
        borderRadius: '8px',
        border: '2px solid #161420',
        background: '#191724',
      }}
      autoClose={3000}
    />
  );
}
