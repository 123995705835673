import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { PostDto } from 'api/posts/dto/post.dto';
import RightArrowIcon from 'assets/icons/RightArrowIcon';

import s from './PostDetail.module.scss';

interface PostDetailProps {
  post: PostDto;
}

export default function PostDetail({ post }: PostDetailProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  return (
    <div className={s.detail}>
      <div
        className={classNames({
          [s.detail__header]: true,
          [s.active]: isOpen,
        })}
        onClick={toggleOpen}
      >
        <h4 className={s.detail__title}>Detail:</h4>
        <span
          className={classNames({
            [s.detail__arrow]: true,
            [s.rotate]: isOpen,
          })}
        >
          <RightArrowIcon />
        </span>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: '0px' }}
            className={s.detail__statsWrapper}
          >
            <div className={s.detail__stats}>
              <div className={s.detail__stat}>
                <span>ID:</span>
                <p>{post.id}</p>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
