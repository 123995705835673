import { SVGProps } from 'react';

function RightArrowIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1789 4.43057C10.4934 4.161 10.9669 4.19743 11.2365 4.51192L17.2365 11.5119C17.4772 11.7928 17.4772 12.2072 17.2365 12.4881L11.2365 19.4881C10.9669 19.8026 10.4934 19.839 10.1789 19.5695C9.86442 19.2999 9.82799 18.8264 10.0976 18.5119L15.6792 12L10.0976 5.48811C9.82799 5.17361 9.86442 4.70014 10.1789 4.43057Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default RightArrowIcon;
