export const calcTime = (date: string) => {
  const [newDate] = date.split('T');
  const [year, month, day] = newDate.split('-');

  return `${day}/${month}/${year}`;
};

export const createdUserDate = (value: string) => {
  const [newDate, rest] = value.split('T');
  const [time] = rest.split('.');
  const [year, month, day] = newDate.split('-');
  const [hour, minutes] = time.split(':');

  return `${hour}:${minutes} ${day}.${month}.${year}`;
};
