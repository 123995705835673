import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { useLogin } from 'hooks/auth/useLogIn';
import { AuthTabsType } from 'types/Auth';
import GoogleIcon from 'assets/icons/GoogleIcon';

import s from './index.module.scss';

interface AuthFormProps {
  tabs: AuthTabsType;
}

interface FormData {
  email: string;
  password: string;
}

export default function AuthForm({ tabs }: AuthFormProps) {
  const { register, handleSubmit } = useForm<FormData>();

  const { mutate: logIn, error: logInError } = useLogin();

  const onSubmit = ({ email, password }: FormData) => {
    logIn({ provider: 'email-and-password', email, password });
  };

  const handleLoginGoogle = useCallback(() => {
    logIn({ provider: 'google' });
  }, [logIn]);

  useEffect(() => {
    if (logInError?.message) {
      toast.error(logInError.message);
    }
  }, [logInError?.message]);

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
      <Input placeholder="E-Mail" {...register('email', { required: true })} />
      <Input
        placeholder="Пароль"
        type="password"
        {...register('password', { required: true })}
      />
      <div className={s.socials}>
        <p>Использование социальных сетей:</p>
        <Button className={s.socials__button} onClick={handleLoginGoogle}>
          <GoogleIcon /> Google
        </Button>
      </div>
      <Button type="submit" variant="filled" className={s.form__button}>
        {tabs === 'login' ? 'Войти' : 'Зарегистрироваться'}
      </Button>
    </form>
  );
}
