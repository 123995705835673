import { forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import s from './Input.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className = '', ...props }, ref) => {
    return (
      <label
        className={classNames({
          [s.label]: true,
          [className]: className !== '',
        })}
      >
        <input className={s.input} ref={ref} type="text" {...props} />
      </label>
    );
  },
);

export default Input;
