import { SVGProps } from 'react';

function ShortArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 10 6"
      fill="none"
      {...props}
    >
      <path
        d="M0.91683 5.04102L5.00016 0.957682L9.0835 5.04102"
        stroke="#D1D1D6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShortArrow;
