import MobileHeader from 'components/MobileHeader';
import Loader from 'components/UI/Loader';
import { useFetchStrikesUsers } from 'hooks/query/useFetchStrikesUsers';
import StrikeUserCard from './components/StrikeUserCard';

import s from './StrikeUsersPage.module.scss';

export default function StrikeUsersPage() {
  const { users, isLoadingUsers } = useFetchStrikesUsers();

  if (isLoadingUsers) {
    return (
      <div className="loaderWrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <MobileHeader title="Strikes - Users" isArrow />
      <h2 className={s.title}>Strikes - Users</h2>
      {users
        ? users.map((user) => <StrikeUserCard key={user.id} user={user} />)
        : null}
    </div>
  );
}
