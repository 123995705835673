import { useMutation } from '@tanstack/react-query';
import { signOut } from 'firebase/auth';

import { auth } from 'utils/firebase';

export function useLogout() {
  return useMutation<void, Error, void>({
    mutationFn: () => {
      return signOut(auth);
    },
  });
}
