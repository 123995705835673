import { useQuery } from '@tanstack/react-query';

import { UserDto } from 'api/user/dto/user.dto';
import UserMethods from 'api/user/UserMethods';

export function useFetchStrikesUsers() {
  const { data: users, isLoading: isLoadingUsers } = useQuery<UserDto[]>({
    queryKey: ['users'],
    queryFn: () => UserMethods.getAllStrikesUsers(),
  });

  return { users, isLoadingUsers };
}
