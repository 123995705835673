import { useCallback, useMemo } from 'react';
import { InView } from 'react-intersection-observer';

import MobileHeader from 'components/MobileHeader';
import Loader from 'components/UI/Loader';
import { useFetchInfiniteStrikesPosts } from 'hooks/query/useFetchInfiniteStrikesPosts';
import PostCard from './components/PostCard';

import s from './StrikePostsPage.module.scss';

export default function StrikePostsPage() {
  const {
    data: infinitePostsData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchInfiniteStrikesPosts();

  const posts = useMemo(() => {
    if (infinitePostsData === undefined) return [];
    return infinitePostsData.pages.flatMap((p) => p);
  }, [infinitePostsData]);

  const onScrollToEnd = useCallback(() => {
    if (isLoading) return;
    fetchNextPage();
  }, [isLoading, fetchNextPage]);

  if (isLoading) {
    return (
      <div className="loaderWrapper">
        <Loader />
      </div>
    );
  }

  return (
    <section className={s.strikePosts}>
      <MobileHeader title="Strikes - Posts" isArrow />
      <h2 className={s.title}>Strikes - Posts</h2>
      <div className={s.posts}>
        {posts.length > 0
          ? posts.map((post) => <PostCard key={post.id} post={post} isSkip />)
          : null}
      </div>
      {!isLoading && !isFetchingNextPage && hasNextPage && (
        <InView as="div" onChange={(inView) => inView && onScrollToEnd()} />
      )}
      {isFetchingNextPage && (
        <div className={s.loaderWrapper}>
          <Loader />
        </div>
      )}
    </section>
  );
}
