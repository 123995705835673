import axios, { AxiosRequestConfig } from 'axios';
import store from 'store';

export default class Api {
  protected address: string = process.env.REACT_APP_API_ENDPOINT || 'localhost';
  protected token: string | null = null;

  private updateToken() {
    const { token } = store.getState().auth;

    if (token) {
      this.token = token;
    } else {
      this.token = null;
    }
  }

  constructor() {
    this.updateToken();

    store.subscribe(this.updateToken.bind(this));
  }

  request<Res>(method: string, config: AxiosRequestConfig): Promise<Res> {
    return axios<Res>(this.address + method, config).then(
      (response) => response.data,
    );
  }
}
