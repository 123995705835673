import { useQuery } from '@tanstack/react-query';

import PostsMethods from 'api/posts/PostsMethods';

export function useFetchCommentById(commentId: number) {
  const { data: commentData } = useQuery({
    queryKey: [`comment-${commentId}`],
    queryFn: () => PostsMethods.getCommentDataById(commentId),
    enabled: !!commentId,
  });
  return { commentData };
}
