import { SVGProps } from 'react';

export default function SubscribeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.9529 11.6444L20.9159 9.31639C20.8199 9.20539 20.7719 9.06139 20.7839 8.91439L21.0689 5.83639C21.0929 5.56939 20.9159 5.32339 20.6519 5.26339L17.6399 4.57639C17.4959 4.54339 17.3729 4.45339 17.2949 4.32439L15.7169 1.65439C15.5789 1.42039 15.2909 1.32739 15.0419 1.43539L12.2069 2.65339C12.0719 2.71039 11.9189 2.71039 11.7839 2.65339L8.94888 1.43539C8.69988 1.32739 8.41188 1.42339 8.27388 1.65439L6.69888 4.31539C6.62388 4.44139 6.49788 4.53439 6.35388 4.56439L3.34488 5.23939C3.08088 5.29939 2.90388 5.54539 2.92788 5.81239L3.21288 8.90239C3.22488 9.04939 3.17988 9.19339 3.08388 9.30439L1.04388 11.6444C0.866883 11.8484 0.866883 12.1484 1.04388 12.3524L3.08088 14.6834C3.17688 14.7944 3.22488 14.9384 3.21288 15.0854L2.92788 18.1754C2.90388 18.4424 3.08088 18.6884 3.34488 18.7484L6.35988 19.4294C6.50388 19.4624 6.62688 19.5524 6.70188 19.6784L8.27688 22.3394C8.41488 22.5734 8.70288 22.6664 8.95188 22.5584L11.7869 21.3344C11.9219 21.2744 12.0749 21.2744 12.2129 21.3344L15.0479 22.5524C15.2969 22.6604 15.5849 22.5644 15.7229 22.3334L17.2979 19.6724C17.3729 19.5464 17.4959 19.4564 17.6399 19.4234L20.6519 18.7394C20.9159 18.6794 21.0929 18.4334 21.0689 18.1664L20.7839 15.0884C20.7689 14.9414 20.8169 14.7974 20.9159 14.6864L22.9529 12.3584C23.1299 12.1484 23.1299 11.8484 22.9529 11.6444Z"
        fill="url(#paint0_linear_8631_50569)"
      />
      <path
        d="M9.54918 16.3328L6.64518 13.4228C6.37218 13.1498 6.37218 12.7088 6.64518 12.4358L7.17318 11.9078C7.44618 11.6348 7.89018 11.6348 8.16318 11.9078L9.54918 13.2998C9.82218 13.5728 10.2662 13.5728 10.5392 13.2998L15.5402 8.28678C15.8132 8.01378 16.2572 8.01378 16.5302 8.28678L17.0582 8.81478C17.3312 9.08778 17.3312 9.52878 17.0582 9.80178L10.5392 16.3328C10.2662 16.6088 9.82218 16.6088 9.54918 16.3328Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8631_50569"
          x1="-3.16739"
          y1="11.9969"
          x2="25.1525"
          y2="26.3926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E5992" />
          <stop offset="0.505208" stopColor="#6A19AB" />
          <stop offset="1" stopColor="#3054BB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
