import { useState } from 'react';

import ConfirmModal from 'components/modals/ConfirmModal';
import Button from 'components/UI/Button';
import { UserDto } from 'api/user/dto/user.dto';
import { useMutationGenerateAvatar } from 'hooks/mutations/useMutationGenerateAvatar';
import SubscribeIcon from 'assets/icons/SubscribeIcon';

import s from './ProfileInfo.module.scss';

interface ProfileInfoProps {
  user: UserDto;
}

export default function ProfileInfo({ user }: ProfileInfoProps) {
  const { generateNewAvatar } = useMutationGenerateAvatar();
  const [isModal, setIsModal] = useState(false);

  const closeModal = () => {
    setIsModal(false);
  };

  const handleGenerateAvatar = () => {
    generateNewAvatar(user.id);
    closeModal();
  };

  return (
    <section className={s.user}>
      <ConfirmModal
        closeModal={closeModal}
        isOpen={isModal}
        handleConfirm={handleGenerateAvatar}
      />
      <div className={s.user__avatarWrapper}>
        <img src={user.avatar} className={s.user__avatar} alt="avatar" />
      </div>
      <div className={s.info}>
        <div className={s.info__nickname}>
          <h4>{user.nickname}</h4>
          {user.adapty.is_active && <SubscribeIcon />}
        </div>
        <div className={s.info_desc}>{user.description}</div>
        <Button
          className={s.info__button}
          onClick={() => {
            setIsModal(true);
          }}
        >
          Recreate avatar
        </Button>
      </div>
      <div className={s.stats}>
        <div className={s.stat}>
          <span className={s.stat__value}>{user.strikes_count}</span>
          <span className={s.stat__text}>Strikes</span>
        </div>
        <div className={s.stat}>
          <span className={s.stat__value}>{user.subscriptions_count}</span>
          <span className={s.stat__text}>Following</span>
        </div>
        <div className={s.stat}>
          <span className={s.stat__value}>{user.subscribers_count}</span>
          <span className={s.stat__text}>Followers</span>
        </div>
        <div className={s.stat}>
          <span className={s.stat__value}>{user.likes_count}</span>
          <span className={s.stat__text}>Likes</span>
        </div>
      </div>
    </section>
  );
}
