import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';

import { setAuthInitialization } from 'store/slices/authSlice';
import { useAppDispatch } from 'hooks/store';
import { auth } from 'utils/firebase';

interface EmailAndPasswordPayload {
  provider: 'email-and-password';
  email: string;
  password: string;
}

interface ApplePayload {
  provider: 'apple';
}

interface GooglePayload {
  provider: 'google';
}

type Payload = EmailAndPasswordPayload | ApplePayload | GooglePayload;

export function useLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation<UserCredential, Error, Payload>({
    mutationFn: (payload) => {
      switch (payload.provider) {
        case 'email-and-password':
          return signInWithEmailAndPassword(
            auth,
            payload.email,
            payload.password,
          );
        case 'apple': {
          const provider = new OAuthProvider('apple.com');
          return signInWithPopup(auth, provider);
        }
        case 'google': {
          const provider = new GoogleAuthProvider();
          return signInWithPopup(auth, provider);
        }

        default: {
          return Promise.resolve({} as UserCredential);
        }
      }
    },
    onSuccess() {
      navigate('/');
      dispatch(setAuthInitialization(true));
    },
  });
}
