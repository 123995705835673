import { useCallback, useMemo, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';

import LightBox from 'components/LightBox';
import ConfirmModal from 'components/modals/ConfirmModal';
import Button from 'components/UI/Button';
import Category from 'components/UI/Category';
import Loader from 'components/UI/Loader';
import UserInfo from 'components/UserInfo';
import { IComment } from 'api/posts/dto/comments.dto';
import { IPost, PostDto } from 'api/posts/dto/post.dto';
import { useMutationRemoveComment } from 'hooks/mutations/useMutationRemoveComment';
import { useFetchCommentById } from 'hooks/query/useFetchCommentById';
import { useFetchInfiniteChildrenComments } from 'hooks/query/useFetchInfiniteChildrenComments';
import CommentIcon from 'assets/icons/CommentIcon';
import LikeIcon from 'assets/icons/LikeIcon';
import ReplyIcon from 'assets/icons/ReplyIcon';

import s from './CommentCard.module.scss';

interface CommentCardProps {
  comment: IComment;
  isChildren?: boolean;
  post?: PostDto;
  depth?: number;
}

export default function CommentCard({
  comment,
  isChildren,
  post,
  depth = 0,
}: CommentCardProps) {
  const asset = comment.asset;
  const user = comment.user;
  const [isModal, setIsModal] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const maxDepth = 2;
  const currentDepth = useRef(depth > maxDepth ? maxDepth : depth);

  const {
    data: infiniteChildrenCommentsData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchInfiniteChildrenComments({
    childrenId: comment.id,
    parentId: comment.post_id,
    isChildren: comment.children?.counts || 0,
  });

  const childrenComments = useMemo(() => {
    if (infiniteChildrenCommentsData === undefined) return [];
    return infiniteChildrenCommentsData.pages.flatMap((p) => p);
  }, [infiniteChildrenCommentsData]);

  const { commentData } = useFetchCommentById(comment.id);
  console.log(commentData);
  console.log(comment);

  const closeModal = useCallback(() => {
    setIsModal(false);
  }, []);

  const { deletePost } = useMutationRemoveComment({
    postId: comment.id,
  });

  const handleConfirm = useCallback(() => {
    deletePost(comment.id);
    closeModal();
  }, [closeModal, deletePost, comment.id]);

  const onScrollToEnd = useCallback(() => {
    if (isLoading) return;
    fetchNextPage();
  }, [isLoading, fetchNextPage]);

  return (
    <>
      {isFullScreen && (
        <LightBox
          sourceUrl={asset.source_url}
          imageUrl={asset.thumbnail_url}
          onCloseRequest={() => setIsFullScreen(false)}
        />
      )}
      <ConfirmModal
        isOpen={isModal}
        closeModal={closeModal}
        handleConfirm={handleConfirm}
      />
      <div
        className={s.container}
        style={{
          marginLeft: isChildren ? `${currentDepth.current * 32}px` : '0px',
        }}
      >
        <div
          className={s.imageWrapper}
          onClick={() => {
            setIsFullScreen(true);
          }}
        >
          <img src={asset?.thumbnail_url} alt="report" className={s.image} />
        </div>
        <div className={s.content}>
          <div className={s.header}>
            <UserInfo
              user={{ avatar: user?.avatar, nickname: user?.nickname }}
              isPost
            />
            {commentData?.parent ? (
              <>
                <ReplyIcon />
                <div className={s.headerCard}>
                  <img src={commentData.parent.asset?.thumbnail_url} alt="" />
                  <p>{commentData.parent.asset?.source}</p>
                </div>
              </>
            ) : (
              <>
                <ReplyIcon />
                <div className={s.headerCard}>
                  <img src={post?.assets[0]?.thumbnail_url} alt="" />
                  <p>{post?.assets[0]?.source}</p>
                </div>
              </>
            )}
          </div>
          <div className={s.post}>
            <p className={s.postPrompt}>{asset?.source}</p>
            <div className={s.postTeg}>
              {asset?.style && <Category>{asset.style}</Category>}
            </div>
          </div>
          <div className={s.cardFooter}>
            <div className={s.buttons}>
              <Button
                variant="red"
                className={s.button}
                onClick={() => {
                  setIsModal(true);
                }}
              >
                Remove
              </Button>
            </div>
            <div className={s.postStatistic}>
              <div className={s.statInfo}>
                <LikeIcon />
                <p>{comment.likes_count}</p>
              </div>
              <div className={s.statInfo}>
                <CommentIcon />
                <p>{comment?.children?.counts || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {childrenComments.length > 0 &&
        childrenComments.map((item) => {
          return (
            <CommentCard
              key={item.id}
              comment={item}
              isChildren
              depth={currentDepth.current + 1}
            />
          );
        })}
      {!isLoading && !isFetchingNextPage && hasNextPage && (
        <InView as="div" onChange={(inView) => inView && onScrollToEnd()} />
      )}
      {isFetchingNextPage && (
        <div className={s.loaderWrapper}>
          <Loader />
        </div>
      )}
    </>
  );
}
