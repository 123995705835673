import DashboardIcon from 'assets/icons/DashboardIcon';
import PostsIcon from 'assets/icons/PostsIcon';
import ShieldIcon from 'assets/icons/ShieldIcon';
import TrendingIcon from 'assets/icons/TrendingIcon';
import UsersGroupIcon from 'assets/icons/UsersGroupIcon';

export const NAVIGATIONS = [
  {
    id: 1,
    link: '/',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    id: 2,
    link: '/trending',
    title: 'Trending',
    icon: <TrendingIcon />,
  },
  // {
  //   id: 2,
  //   link: '/configs',
  //   title: 'Configs',
  //   icon: <ConfigsIcon />,
  // },
];

export const STRIKESNAVIGATIONS = [
  {
    id: 1,
    link: '/strike/users',
    title: 'Users',
    icon: <UsersGroupIcon />,
  },
  {
    id: 2,
    link: '/strike/posts',
    title: 'Posts',
    icon: <PostsIcon />,
  },
];

export const FOOTERTABS = [
  {
    id: 1,
    link: '/strike',
    title: 'Strikes',
    icon: <ShieldIcon />,
    iconActive: <ShieldIcon isActive />,
  },
  {
    id: 2,
    link: '/',
    title: 'Dashboard',
    icon: <DashboardIcon />,
    iconActive: <DashboardIcon isActive />,
  },
  {
    id: 3,
    link: '/trending',
    title: 'Trending',
    icon: <TrendingIcon />,
    iconActive: <TrendingIcon isActive />,
  },
];
